@use 'theme';
@use 'vars';
@use 'fonts';

.footer {
  height: vars.$footer-h;
  padding: 0 40px;

  a {
    text-decoration: none;
  }

  @media screen and (max-width: 991px) {
    height: vars.$footer-h-mobile;
  }
}

.footer-container {
  @include theme.get-color('border-top', 'color-border-lightest', 1px solid);
  padding: 32px 0 32px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  @media screen and (max-width: 991px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    padding: 20px 0;
  }
}

.footer-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 48px;

  @media screen and (max-width: 991px) {
    gap: 20px;
    flex-direction: column;
    align-items: flex-start;
  }
}

.footer-logo {
  height: 40px;
  padding-bottom: 0px;
  width: auto;

  @media screen and (max-width: 991px) {
    height: 24px;
    padding-bottom: 0px;
  }

  > img {
    max-width: 147px;
  }
}

.footer-content {
  @extend .text-note, .text-capital;
  display: flex;
  align-items: center;
  align-self: flex-end;
  gap: 20px;

  & > a {
    @extend .text-note, .text-capital;
    @include theme.get-color('color', 'color-text-muted');

    &:hover {
      text-decoration: none;
      @include theme.get-color('color', 'color-text-hover');
    }
  }

  @media screen and (max-width: 991px) {
    gap: 16px;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-self: auto;
    width: 100%;

    & > a {
      width: calc(50% - 16px); //exclude gap
      text-align: start;
    }
  }
}

.footer-divider {
  width: 1px;
  height: 24px;
  @include theme.get-color('background-color', 'color-text-extra-light');

  @media screen and (max-width: 991px) {
    width: 100%;
    height: 1px;
    margin-top: 4px !important;
  }
}

.version-number {
  @extend .text-i;
  @include theme.get-color('color', 'color-text-light');

  @media screen and (max-width: 991px) {
    margin-top: 4px;
  }
}

.footer-contact-list {
  display: flex;
  align-items: center;
  gap: 20px;

  @media screen and (max-width: 991px) {
    width: 100%;
    gap: 24px;
    justify-content: flex-start;
    padding-bottom: 0;
  }
}
