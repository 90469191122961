.cookie {
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 8px 0;
  display: flex;
  z-index: 8;

  .overlay {
    background-color: #202327;
    opacity: 0.8;
    z-index: 9;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .content {
    background: transparent;
    z-index: 10;
    color: white;
    width: 100%;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto !important;

    .privacy {
      color: white;
      text-decoration: underline;
    }
    .privacy:hover {
      color: bisque;
    }
  }
  .btn-confirm {
    align-items: center;
    width: 85px;
  }

  @media (max-width: 650px) {
    .content {
      flex-direction: column;

      .cookie-note-text {
        margin-bottom: 16px;
      }
    }
  }
}
