@use '../../../../../scss/vars';

.graph-container {
  width: 100%;
  margin-bottom: 24px;
  position: relative;
  margin-top: 8px;

  & .node {
    display: flex;
    flex-direction: column;

    & .title {
      margin: 0;
      line-height: 30px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      img,
      i {
        margin-left: 8px;
        color: #{vars.$blue};
      }

      i:before {
        font-size: 14px;
      }
    }

    & .contents {
      flex-grow: 1;
      padding: 4px;
      font-size: 1rem;

      & .item {
        margin: 2px 0;
        text-align: center;
      }
    }
  }

  & .export-btn {
    right: 16px;
    top: 16px;
    visibility: hidden;
    position: absolute;
  }

  &:hover .export-btn {
    visibility: visible;
  }

  & .zoom-buttons {
    right: 0;
    top: 0;
    position: absolute;

    & button:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.svg-container {
  width: 100%;
  min-height: 200px;
}

.base64-container {
  & .overlay {
    display: inline-flex;
    align-items: center;
    gap: 16px;
  }
}

.base64-suffix {
  color: #333;
  font-size: 14px;
  font-weight: 700;
}

.decode-view-container {
  word-break: break-all;

  & .encoded {
    padding: 8px;
    border: 1px solid rgb(209, 213, 218);
  }

  & .actions {
    display: flex;
    align-items: center;
    gap: 16px;
    margin: 16px 8px 8px;
  }

  & .decoded {
    max-height: 560px;
    width: 925px;
    overflow-y: auto;

    & .decoded-content-wrapper p {
      max-width: 100%;
      word-break: break-all;
      line-break: anywhere;
    }
  }
}

.hex-viewer-container {
  height: 520px;
}
