@use '../../../../../scss/theme';
@use '../../../../../scss/vars';

.modal {
  .modal-filter-container {
    @include theme.get-color('border', 'color-border-lightest', 1px solid);
    border-bottom: none !important;
    margin-top: -16px;
    display: grid;
    grid-template-columns: 1fr 3.8fr;
    height: calc(100% - 36px);

    @media (max-width: 1050px) {
      grid-template-columns: 1fr 2.5fr;
    }

    .modal-filter-items {
      display: flex;
      flex-direction: column;
      width: 100%;

      > div {
        padding: 10px 8px 10px 20px;
        @include theme.get-color('background-color', 'color-modal-filter-background');

        &:first-child,
        &.active {
          @include theme.get-color('background-color', 'color-white');
        }

        &:not(:first-child):not(.active) {
          &:hover {
            @include theme.get-color('background-color', 'color-modal-filter-background-hover');
          }
        }

        &:not(:first-child) {
          @include theme.get-color('border-top', 'color-border-lightest', 1px solid);
          cursor: pointer;
        }

        &:not(.active) {
          @include theme.get-color('border-right', 'color-border-lightest', 1px solid);
        }

        &:last-child {
          flex-grow: 1;
        }
      }
    }

    .modal-filter-viewer {
      width: 100%;
      padding: 20px 20px 18px;
    }
  }
}
