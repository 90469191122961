.archives-container {
  & .archives {
    @media (min-width: 768px) {
      &:first-of-type {
        padding-right: 24px !important;
        padding-left: 0 !important;
      }
      &:nth-of-type(2) {
        padding-left: 24px !important;
        padding-right: 0 !important;
      }
    }

    .archives-items {
      margin-right: -10px;
      margin-left: -20px;
      padding-left: 20px;
    }

    .spinner-border {
      width: 12px;
      height: 12px;
      border-width: 2px;
      position: absolute;
      top: 10px;
      left: -16px;
    }
  }
}
