@use '../../../../scss/vars';
@use '../../../../scss/theme';

.minus-menu-h-100 {
  height: calc(100% - #{vars.$top-menu-total-h});
  padding: 0 105px;
}

.underlined {
  text-decoration: underline;
}

.text-justify {
  white-space: pre-line;
}

.list-group {
  .list-group-item {
    a:not(.natural-link) {
      color: inherit;
    }

    a {
      text-decoration: none;
    }

    .alert {
      font-size: 14px;
    }
  }
}

.nav-tabs {
  > a.nav-link:not(.text-default) {
    text-transform: capitalize;
  }
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.header-container-column {
    flex-direction: column;
    justify-content: baseline;
    align-items: flex-start;
  }
}

.report-block {
  .card-body {
    h2.card-title {
      text-transform: capitalize;
    }
  }
}

.comma {
  margin-right: 5px;
}

.header-note {
  font-size: 60%;

  .header-note-icon {
    opacity: 0.5;
    font-size: 70%;
    margin-right: 6px;
  }
}

.accordion {
  .card-header {
    cursor: pointer;
  }

  &.archives-month {
    .collapse {
      max-height: calc(100vh - 300px);
    }
  }

  .collapse {
    max-height: calc(100vh - 200px);
    overflow-y: auto;

    .card-body {
      &.table-content {
        padding: 0;

        table {
          border: none;
          margin-bottom: 0 !important;

          td,
          th {
            &:first-child {
              border-left: none;
            }
            &:last-child {
              border-right: none;
            }
          }

          tr:last-child {
            td {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
}

.card-header-tabs {
  margin: 0;

  &.border-bottom {
    border-bottom: 1px solid #dee2e6;
  }
}

a {
  .text {
    margin-right: 10px;
  }
}

.icon-button {
  cursor: pointer;

  &.icon-copy {
    margin-left: 9px;
    color: initial;
    display: inline-block;
    @include theme.get-color('color', 'color-clipboard-btn');

    &.admin-edit-icon {
      margin-left: 0;
      font-size: 17px;
    }

    &.easy-target {
      padding: 7px;
      margin: -7px;
    }
  }

  &:hover {
    color: #{vars.$blue200} !important;
  }
}

.list-no-type {
  padding-left: 0;

  li {
    list-style: none;
    padding-left: 0;
  }
}

.narrow-table {
  max-width: 100%;

  th {
    padding: 0 16px 0 0;
    padding-bottom: 5px;
  }

  td {
    padding: 0 16px 0 0;
    word-break: break-all;
    overflow-wrap: anywhere;
  }
}

.scans-table {
  @include theme.get-color('--bs-table-bg', 'color-background');
  @include theme.get-color('--bs-table-hover-bg', 'color-background-emphasized');
  @include theme.get-color('--bs-table-color', 'color-text');
  @include theme.get-color('--bs-table-hover-color', 'color-text');
  max-width: 100%;

  th {
    padding: 4px 48px 4px 0;
    padding-bottom: 5px;
    max-width: 25%;
    vertical-align: middle;
  }

  td {
    padding: 4px 48px 4px 0;
    word-break: break-all;
    overflow-wrap: anywhere;
    max-width: 25%;
    vertical-align: middle;
  }
}

.text-break-all {
  word-break: break-all !important;
}

.text-muted-light {
  @include theme.get-color('color', 'color-text-light');
}

.links-list a {
  margin-right: 10px;
  text-decoration: none;
}

.report-block-top-note {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
}

.page-note-important {
  @include theme.get-color('color', 'color-text');
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
}

.report-icon {
  width: 32px;
  height: auto;
}

.right-top {
  position: absolute;
  right: 16px;
  top: 16px;

  @media screen and (max-width: 380px) {
    position: static;
    width: 100%;
    display: inline-block;
  }
}

.right-bottom {
  position: absolute;
  right: 16px;
  bottom: 16px;

  @media screen and (max-width: 380px) {
    position: static;
    width: 100%;
    display: inline-block;
  }
}

.file-icon {
  width: 16px;
  min-width: 16px;
  height: auto;
  display: flex;
  align-items: center;
}

.file-item-with-icon {
  display: flex;
  align-items: center;

  & .file-icon {
    width: 32px;
    min-width: 32px;
    margin-right: 16px;
    height: auto;
  }

  & .file-name {
    font-size: 20px;
    font-weight: 600;
  }

  & .file-size {
    margin-left: 8px;
    font-size: 16px;
  }
}

.reports-row {
  border-bottom: 0.5px solid #666;
  &:hover {
    cursor: pointer;
    background-color: #eee;
  }

  & span {
    font-size: 14px;

    &.verdict {
      border-radius: 10px;
      color: white;
      font-weight: 600;
      padding: 4px 8px;
      line-break: normal;
    }
  }
}

.with-hidden-icons {
  .icon-copy {
    opacity: 0;
  }

  &:hover {
    .icon-copy {
      opacity: 1;
    }
  }
}

.general-mark {
  margin: 0;

  &.left-mark {
    margin-right: 5px;
  }

  &.right-mark {
    margin-left: 5px;
  }
}

a.prevalence {
  text-decoration: none;
  color: white;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    text-decoration: none;
  }

  & .prevalence-badge {
    font-weight: 400;
    text-align: center;
    min-width: 18px;
    border-radius: 0;
    margin-top: -2px;
    margin-bottom: -2px;
  }
}

.prevalence-item {
  margin: 0;
  padding: 2px 8px;
  line-height: 1;
  border-radius: 0;
}

.rw-popup-container {
  min-width: 480px;
  left: -1em;
  right: unset;

  @media screen and (max-width: 1280px) {
    left: unset;
    right: -1em;

    @media screen and (max-width: 560px) {
      left: -1em;
    }
  }
}

.highlight {
  background-color: #{vars.$yellow-300};
}

.clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.clamp-2 {
  -webkit-line-clamp: 2;
}
.clamp-3 {
  -webkit-line-clamp: 3;
}
.clamp-4 {
  -webkit-line-clamp: 4;
}
.clamp-5 {
  -webkit-line-clamp: 5;
}
.clamp-6 {
  -webkit-line-clamp: 6;
}

.iscore {
  min-width: 60px;
}

.animate-in-out {
  animation-name: eye-catch;
  animation-delay: 0;
  animation-direction: normal;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
}

@keyframes eye-catch {
  0% {
    color: #1e88e5;
  }
  50% {
    color: #2196f3;
  }
  100% {
    color: #1e88e5;
  }
}

.thumbs-list {
  gap: 20px 0;
}

.thumbnail-container {
  padding: 0 20px;
  display: inline-flex;
  align-items: center;
  min-height: 80px;
  cursor: pointer;

  @include theme.get-color('background-color', 'color-background');

  &.single-preview {
    padding: 0;
  }

  &:hover {
    @include theme.get-color('background-color', 'color-hover-background');
  }

  .image-not-ready,
  .image-not-found {
    font-size: 14px;
    text-align: center;
  }

  .image-not-found {
    background-color: transparent;
  }

  &.disabled:hover {
    cursor: default;
    background: transparent;
  }
}

.data-block .data-row .value {
  .thumbnail-container {
    padding: 0;
  }
}

.collapse-highlighted-content {
  > pre {
    background: transparent !important;
  }
}

.blurred-content {
  filter: blur(0.3rem);
}

.skeleton {
  @include theme.get-color('background', 'color-control-disabled');
  height: 20px;
  margin-bottom: 12px;
  border-radius: 2px;
}

.skeleton-text {
  width: 150px;
}

.skeleton-boolean {
  width: 4ch;
}

.skeleton-warning {
  background: #f05959c9 !important;
}

.hidden-tabs-titles {
  > .nav.nav-tabs {
    display: none !important;
  }
}

.flex-button {
  flex: 1 1 0px;
  width: 150px;
  max-width: 150px;
}

.error-text {
  color: #d00300;
}

.bordered-list {
  padding-left: 0;
  list-style: none;

  li {
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 20px;

    &:last-of-type {
      border-bottom: none;
      margin-bottom: 0;
    }

    > .data-block {
      margin-bottom: 15px;
    }
  }
}

.group-mitre-details {
  &:not(:last-child) {
    margin-bottom: #{vars.$gap4};
  }
}

.advertisement-card {
  .card-header {
    padding: 4px 16px;
    background-color: #{vars.$page200};
  }
}

.flex-item {
  flex: 1 1 0;
}
