@use '../../../../../scss/vars';
@use '../../../../../scss/fonts';
@use '../../../../../scss/theme';

.report-nav-controls {
  display: flex;
  justify-content: space-between;
  height: #{vars.$gap7};
  margin-bottom: #{vars.$gap7};

  .report-navpath {
    display: flex;
    height: 100%;

    .navpath-item {
      display: flex;
      align-items: center;

      &:not(:last-child):after {
        @include fonts.icon-font;

        content: '\e94b';
        margin: 0 4px;
      }

      .title,
      a {
        font-size: inherit;
        text-transform: none;
        word-break: break-all;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 270px;
        vertical-align: bottom;
      }
    }
  }

  .report-controls-collapse {
    .navbar-nav {
      gap: 24px;
      align-items: flex-end;
    }
  }

  .separate-buttons {
    display: flex;
    gap: 24px;
    align-items: center;

    .dropdown-toggle:after {
      display: none;
    }
  }

  .navbar {
    display: flex;
    justify-content: flex-end;

    .navbar-toggler {
      margin-right: 0 !important;
      display: block !important;
    }
  }

  .report-controls-collapse {
    position: absolute;
    top: 28px;
    right: -2px;
    min-width: 260px;
    z-index: 101;
    border: 1px solid #{vars.$page200};
    @include theme.get-color('background-color', 'color-white');
    padding: 8px 0;
    transition: opacity 0.1s;
    display: none !important;

    &.show {
      display: flex !important;
    }

    .navbar-nav {
      gap: 0;
      flex-direction: column;

      > * {
        width: 100%;
        text-align: left;
        padding: 6px 16px;

        &.dropdown {
          padding: 0 16px;
        }

        .dropdown-menu {
          position: static;
          border: none;
          box-shadow: none;
          padding: 0 0 0 24px;

          > * {
            padding: 6px 0;
            font-weight: 500 !important;

            &:hover {
              @include theme.get-color('color', 'color-primary-hover');
              background-color: inherit;
            }
          }
        }
      }
    }
  }

  .mobile-menu-opened-overlay {
    top: 0;
    z-index: 100;
  }
}
