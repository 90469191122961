@use '../../../../../scss/theme';
@use '../../../../../scss/vars';

.report-container {
  height: 100%;
}

.report-page-component {
  padding: 0;

  @media (max-width: 1279px) {
    padding: 0;
  }

  @media (max-width: 991px) {
    margin-top: 60px;
  }

  .report-content-wrapper {
    display: flex;
    align-items: stretch;
    position: relative;

    .report-content-main-col {
      padding: 40px 20px;

      @media (min-width: 1680px) {
        max-width: 1600px;
        flex-grow: 1;
        margin: 0 auto;
      }

      @media (min-width: 1280px) and (max-width: 1679px) {
        width: 1200px;
        margin: 0 auto;
      }

      @media (max-width: 1279px) {
        flex-grow: 1;
        width: 100%;
      }

      @media (max-width: 991px) {
        padding: 40px 0px;
      }
    }
  }
}

.report-page-component,
.modal {
  .report-block {
    margin-bottom: 35px;
  }

  .modal-body {
    padding: 0 15px;
  }
}

.tags-block {
  color: #4a148c;
}

.report-switch {
  width: 96px;
  height: 31px;
}

.long-string {
  .icon-button {
    margin-left: 5px;
  }
}

.full-long-string-container {
  word-break: break-all;
  padding: 8px;
  background: rgb(253, 253, 253);
}

.boolean {
  color: rgb(128, 33, 216);
  font-weight: bold;
}

.opacity-3 {
  opacity: 0.3;
}

.font-size-14 {
  font-size: 14px;
}

.small-90 {
  small,
  .small {
    font-size: 90%;
  }
}

.suspicious {
  color: rgb(118, 85, 0);
}

.interesting {
  color: #1e88e5;
  padding: 0 8px;
}

.keyword {
  color: rgb(80, 80, 80);
  font-style: italic;
}

.capitalize {
  text-transform: capitalize;
}

.capitalize-first {
  display: inline-block;

  &:first-letter {
    text-transform: uppercase;
  }
}

table.narrow-table.hover tr {
  cursor: pointer;

  &:hover {
    @include theme.get-color('background-color', 'color-hover-background');
  }
}

.card-body {
  .card-embedded-tabs {
    .nav-tabs {
      margin-left: -21px;
      margin-right: -21px;
      margin-bottom: 16px;
      @include theme.get-color('border-bottom', 'color-border', 1px solid);
    }

    .tab-content {
      margin-left: -21px;
      margin-right: -21px;
      margin-bottom: -21px;
      padding: 0 20px 20px 20px;
    }
  }
}

.nav-item.nav-link {
  display: flex;
  align-items: center;
}

.collapse-toggle-panel {
  label {
    margin: 0 5px 0 10px;
    font-size: 15px;
  }

  > button:last-child {
    margin-left: 10px;
  }

  &.moved-for-all-tabs {
    position: absolute;
    top: -103px;
    right: -14px;
  }
}

.extracted-inline-token {
  @extend .text-note;
  @include theme.get-color('color', 'color-text');

  background-color: #{vars.$page100};
  padding: 4px;
  word-break: break-all;
}

.report-thumbnail {
  max-width: 100px;
  max-height: 100px;
  cursor: pointer;
  opacity: 0.9;

  &:hover {
    opacity: 1;
  }
}

.thumbs-list {
  .report-thumbnail {
    max-width: 300px;
    max-height: 300px;
    border: 1px solid #dfdfdf;
  }
}

.hash {
  font-family: 'Roboto', sans-serif;
}

.slider-container {
  display: flex;
  align-items: center;

  .slider-button {
    font-size: 60px;
    opacity: 0.1;
    cursor: pointer;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.3;
    }
  }
}

.copy-icon-hover-show {
  .icon-copy {
    opacity: 0;
    transition: opacity 0.04s;
  }

  tr:hover,
  .tr:hover {
    .icon-copy {
      opacity: 1;
    }
  }
}

.word-break-normal {
  word-break: normal;

  td,
  th {
    word-break: normal !important;
  }
}

.opacity-button {
  opacity: 0.5;
  transition: opacity 0.01s;

  &:hover {
    opacity: 1;
  }
}

.resend-button {
  margin-top: 15px;
}

.fake-nested-list {
  margin-top: -8px;
  list-style: circle;
  padding-left: 40px;
}
