@use 'theme';

.uploads-list-table {
  thead tr,
  .uploads-list-item {
    @include theme.get-color('border', 'color-table-border', 1px solid);
    border-top: none;

    &:first-of-type {
      @include theme.get-color('border-top', 'color-table-border', 1px solid);
    }

    &:hover {
      @include theme.get-color('background', 'color-hover-table-background');
    }

    td {
      padding: 18px 10px;
    }
  }

  tbody .uploads-list-item td:first-child {
    max-width: 200px;
  }
}
