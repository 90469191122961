@use '../theme';
@use '../vars';

.badge {
  --bs-badge-padding-x: 8px;
  --bs-badge-padding-y: 4px;
  --bs-badge-font-size: 12px;
  --bs-badge-font-weight: 400;
}

.badge-fs {
  padding: 0px 8px;
  border-radius: 0;
  display: flex;
  align-items: center;
  width: max-content;
}

.badge-fs-link {
  text-decoration: none;
}

.badge-fs-verdict {
  @extend .text-note-medium;

  @include theme.get-color('color', 'color-white');
  padding: 3px 8px 1px 8px;
  text-transform: uppercase;

  &.verdict-malicious {
    @include theme.get-color('background-color', 'color-malicious');
  }
  &.verdict-likely_malicious {
    @include theme.get-color('color', 'color-text');
    @include theme.get-color('background-color', 'color-likely_malicious');
  }
  &.verdict-suspicious {
    @include theme.get-color('color', 'color-text');
    @include theme.get-color('background-color', 'color-suspicious');
  }
  &.verdict-informational,
  &.verdict-no_threat {
    @include theme.get-color('background-color', 'color-no_threat');
  }
  &.verdict-benign {
    @include theme.get-color('background-color', 'color-benign');
  }
  &.verdict-unknown {
    @include theme.get-color('background-color', 'color-unknown');
  }

  &.short-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 4px;
    min-width: 20px;
  }
}

.badge-fs-verdict-secondary {
  @extend .text-label;

  @include theme.get-color('color', 'color-black');
  padding: 0px 4px;
  text-transform: capitalize;
  border: 1px solid;

  &.verdict-malicious {
    border-color: vars.$red;
    background-color: vars.$red-500;
  }
  &.verdict-likely_malicious {
    border-color: vars.$yellow2;
    background-color: vars.$orange-500;
  }
  &.verdict-suspicious {
    border-color: vars.$yellow;
    background-color: vars.$yellow-500;
  }
  &.verdict-informational,
  &.verdict-no_threat {
    border-color: vars.$green;
    background-color: vars.$green-500;
  }
  &.verdict-benign {
    border-color: vars.$blue;
    background-color: vars.$blue-500;
  }
  &.verdict-unknown {
    border-color: vars.$page1100;
    background-color: vars.$page400;
  }

  &.short-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 20px;
    height: 20px;
    min-width: 20px;
  }
}

.badge-fs-tag {
  padding: 0px 4px;
  line-height: 20px;
  font-size: 12px;
  font-weight: normal;
  border-width: 1px;
  border-style: solid;

  &.tag-lg {
    font-size: 13px;
    padding: 1px 8px;
  }

  .ds3-icon {
    margin-right: -1px;
  }

  &.verdict-malicious {
    background-color: #{vars.$red-600};
    @include theme.get-color('color', 'color-malicious');
    @include theme.get-color('border', 'color-malicious', 1px solid);

    &:not(.no-hover):hover {
      background: #{vars.$red-500};
      color: #{vars.$red100};
      border-color: #{vars.$red100};
    }
  }

  &.verdict-likely_malicious {
    background-color: #{vars.$orange-600};
    color: #{vars.$orange200};
    border: 1px solid #{vars.$orange200};

    &:not(.no-hover):hover {
      background: #{vars.$orange-500};
      color: #{vars.$orange100};
      border-color: #{vars.$orange100};
    }
  }

  &.verdict-suspicious,
  &.verdict-informational,
  &.verdict-no_threat,
  &.verdict-benign,
  &.verdict-unknown {
    background-color: #{vars.$page200};
    color: #{vars.$page1100};
    border: 1px solid #{vars.$page1100};

    &:not(.no-hover):hover {
      background: #{vars.$page300};
      color: #{vars.$page1200};
      border-color: #{vars.$page1200};
    }
  }
}

.badge-fs-info {
  background: #{vars.$blue-600};
  @include theme.get-color('color', 'color-primary');
  @include theme.get-color('border', 'color-primary', 1px solid);

  &:not(.no-hover):hover {
    background: #{vars.$blue-500};
    color: #{vars.$blue100};
    border-color: #{vars.$blue100};
  }
}

.badge-fs-warning {
  background: #fdbd0d;
  color: black !important;
}

.badge.dark-danger {
  background-color: rgb(165, 0, 0);
}

.more-button {
  padding: 0px 8px;
  line-height: 20px;
  font-size: 14px;
  background-color: #{vars.$page200};
  color: #{vars.$page1100} !important;
  border: 1px solid #{vars.$page1100};

  &:not(.no-hover):hover {
    background: #{vars.$page300};
    color: #{vars.$page1200} !important;
    border-color: #{vars.$page1200};

    &.with-tooltip {
      cursor: default;
    }
  }
}

.tag-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;

  &.tags-lg {
    gap: 4px;
  }
}

// Redefine BS classes
.badge {
  &.bg-secondary {
    background-color: #{vars.$page400} !important;
    @include theme.get-color('color', 'color-text');
  }
  &.bg-secondary-light {
    background-color: #{vars.$page200} !important;
    border: 1px solid #{vars.$page900};
    @include theme.get-color('color', 'color-text');
  }
  &.bg-primary {
    background-color: #{vars.$blue} !important;
    @include theme.get-color('color', 'color-white');
  }
}

.badge {
  &.alert-danger {
    background-color: #{vars.$red-600} !important;
    color: #{vars.$red200} !important;
  }
  &.alert-success {
    background-color: #{vars.$green-600} !important;
    color: #{vars.$green200} !important;
  }
}

.helper-badge {
  padding: 0 4px;
  margin: 0;
  @include theme.get-color('color', 'color-text-muted');
  border-radius: 0;
  @include theme.get-color('background-color', 'color-background-selected');
}

.helper-badge.success {
  color: white;
  background-color: #{vars.$green};
}
