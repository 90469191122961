@use 'vars';

.error-page {
  @media screen and (max-width: 1200px) {
    height: auto;
  }

  .error-box {
    width: 25%;
    text-align: center;

    @media screen and (min-width: 480px) and (max-width: 900px) {
      width: 50%;
    }

    @media screen and (max-width: 480px) {
      width: auto;
    }
  }

  .error-code {
    margin-top: -160px;
    font-size: 150px;
    color: rgb(240, 240, 240);
    font-weight: bold;
    line-height: 143px;
  }

  .error-code-custom {
    font-size: 100px;
    color: rgb(240, 240, 240);
    font-weight: bold;
  }

  .error-message {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 25px;
  }
}
