@use 'theme';
@use 'vars';
@use 'fonts';

.init-admin-bg {
  background-image: url('/assets/imgs/pages/init_admin_bg.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 100%;
  height: 100%;
}

.init-admin-logo {
  position: absolute;
  bottom: 0;
  right: 0;

  > img {
    height: 257px;
    z-index: 1;

    @media screen and (max-width: 991px) {
      height: 150px;
    }
  }
}

.init-admin-content {
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 2;
}

.setup-flow-container {
  height: 100%;
  width: 100%;
  position: relative;
  padding: 40px;
  display: flex;
  flex-direction: column;
}

.setup-flow-header {
  width: 100%;
  height: 24px;
  display: flex;
  justify-content: space-between;
}

.setup-flow-header-logo {
  > img {
    height: 24px;
  }
}

.setup-flow-body {
  height: 100%;
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.setup-flow-footer {
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: space-between;
}

.setup-welcome-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.setup-welcome-image-wrapper {
  width: 600px;
  height: 340px;
  position: relative;
  overflow: hidden;
}

.setup-welcome-image {
  width: 300px;
  height: 340px;
  left: 25%;
  top: 0;
  position: absolute;
}

.setup-welcome-pattern {
  width: 600px;
  left: 0;
  top: 45%;
  position: absolute;
}

.setup-flow-card-content {
  width: 600px !important;
  word-wrap: break-word;
}

.setup-step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px;
}
