@use 'fonts';

.ds3 {
  display: block;
  height: 16px;
  width: 16px;
}

.ds3-icon {
  cursor: pointer;
  display: flex;
  align-items: center;

  &::before {
    @include fonts.icon-font;
    position: relative;
    font-size: 16px;

    // Better font rendering
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.long-string {
  .ds3-icon {
    display: inline-block;
  }
}

a {
  > .ds3-icon {
    line-height: 1;
  }
}

.ds3-hover {
  &:hover {
    filter: invert(21%) sepia(100%) saturate(2298%) hue-rotate(214deg) brightness(81%) contrast(89%); // blue200 - Color cannot be used
    cursor: pointer;
  }
}

.ds3-primary {
  filter: invert(26%) sepia(79%) saturate(3299%) hue-rotate(217deg) brightness(106%) contrast(98%); // Color cannot be used
}

.ds3-muted {
  filter: invert(32%) sepia(26%) saturate(382%) hue-rotate(180deg) brightness(86%) contrast(87%); // page900 - Color cannot be used
}

.ds3-light {
  filter: invert(66%) sepia(18%) saturate(147%) hue-rotate(178deg) brightness(86%) contrast(81%); // page600 - Color cannot be used
}

.ds3-light-muted {
  filter: invert(83%) sepia(9%) saturate(110%) hue-rotate(175deg) brightness(92%) contrast(91%); // page400 - Color cannot be used
}

.ds3-notification-bell {
  content: url('../assets/icons/opswat/notification_bell.svg');
}

.ds3-user {
  content: url('../assets/icons/opswat/user.svg');
}

.ds3-search {
  content: url('../assets/icons/opswat/Search.svg');
}

.ds3-solid-down {
  &:before {
    content: '\e9dc';
  }
}

.ds3-solid-up {
  &:before {
    content: '\e9df';
  }
}

.ds3-solid-right {
  &:before {
    content: '\e9de';
  }
}

.ds3-solid-left {
  &:before {
    content: '\e9dd';
  }
}

.ds3-chevron-down {
  &:before {
    content: '\e924';
  }
}

.ds3-chevron-up {
  &:before {
    content: '\e928';
  }
}

.ds3-chevron-right-double {
  &:before {
    content: '\e925';
  }
}

.ds3-chevron-right {
  &:before {
    content: '\e927';
  }
}

.ds3-chevron-left-double {
  &:before {
    content: '\e923';
  }
}

.ds3-chevron-left {
  &:before {
    content: '\e926';
  }
}

.ds3-bars {
  content: url('../assets/icons/opswat/hamburger_menu.svg');
}

.ds3-close {
  &:before {
    content: '\e933';
  }
}

.ds3-upload {
  &:before {
    content: '\e9f1';
  }
}

.ds3-crosshair {
  content: url('../assets/icons/opswat/Crosshair.svg');
}

.ds3-folder-open {
  &::before {
    content: '\e97b';
  }
}

.ds3-help {
  content: url('../assets/icons/opswat/HelpDesk.svg');
}

.ds3-information {
  &:before {
    content: '\e980';
  }
}

.ds3-check {
  &:before {
    content: '\e921';
  }
}

.ds3-check-circle {
  &:before {
    content: '\e92b';
  }
}

.ds3-link {
  &:before {
    content: '\e98c';
  }
}

.ds3-calendar {
  &:before {
    content: '\e919';
  }
}

.ds3-twitter {
  content: url('../assets/icons/opswat/x-icon.svg');
}

.ds3-email {
  &:before {
    content: '\e954';
    line-height: normal; //prevent 20px height to match design
  }
}

.ds3-linkedin {
  content: url('../assets/icons/opswat/linkedin-icon.svg');
}

.ds3-github {
  content: url('../assets/icons/opswat/github-icon.svg');
}

.ds3-divider {
  &:before {
    content: '\e94b';
  }
}

.ds3-download {
  &:before {
    content: '\e950';
  }
}

.ds3-remove {
  &:before {
    content: '\e9c7';
  }
}

.ds3-option {
  &:before {
    content: '\e9ab';
  }
}

.ds3-copy {
  &:before {
    content: '\e93e';
  }
}

.ds3-success {
  &:before {
    content: '\e9a8';
  }
}

.ds3-failed {
  &:before {
    content: '\e9a6';
  }
}

.ds3-box-checked {
  &:before {
    content: '\e9b1';
  }
}

.ds3-external-link {
  &:before {
    content: '\e957';
  }
}

.ds3-warning {
  &:before {
    content: '\e9a0';
  }
}

.ds3-information-block {
  &:before {
    content: '\e9a3';
  }
}

.ds3-eye {
  &:before {
    content: '\e959';
  }
}

.ds3-eye-closed {
  &:before {
    content: '\e958';
  }
}

.ds3-warning-triangle {
  &:before {
    content: '\e9fc';
  }
}

.ds3-warning-circle {
  &:before {
    content: '\e92c';
  }
}

.ds3-file-text {
  &:before {
    content: '\e965';
  }
}

.ds3-filter {
  &:before {
    content: '\e968';
  }
}

.ds3-process {
  &:before {
    content: '\e9bd';
  }
}

.ds3-edit {
  &:before {
    content: '\e953';
  }
}

.ds3-chat-gpt {
  &:before {
    content: '\e9a9';
  }
}

.ds3-search {
  &:before {
    content: '\e9cd';
  }
}

.ds3-add {
  &:before {
    content: '\e901';
  }
}

.ds3-dash {
  &:before {
    content: '\e99b';
  }
}

.ds3-key {
  &:before {
    content: '\e983';
  }
}

.ds3-password {
  &:before {
    content: '\e9b2';
  }
}

.owl-head {
  content: url('../assets/icons/opswat/owl-head.svg');
}

.owl-waiting {
  background-image: url('../assets/icons/opswat/upload-owls.svg');
  background-position: top;
  background-repeat: no-repeat;
}

.owl-hunting {
  background-image: url('../assets/icons/opswat/upload-owls.svg');
  background-position: bottom;
  background-repeat: no-repeat;
}

.owl-catching {
  background-image: url('../assets/icons/opswat/upload-owls.svg');
  background-position: center;
  background-repeat: no-repeat;
}

.ds3-shell {
  &:before {
    content: '\e9d4';
  }
}

.ds3-file-lookup {
  &:before {
    content: '\e970';
  }
}

.ds3-play {
  &:before {
    content: '\e9a2';
  }
}

.ds3-script {
  &:before {
    content: '\e9cc';
  }
}

.ds3-start {
  &:before {
    content: '\e9e2';
  }
}

.ds3-refresh {
  &:before {
    content: '\e9c6';
  }
}

.ds3-file-blank {
  &:before {
    content: '\e966';
  }
}

.ds3-file-export {
  &:before {
    content: '\e962';
  }
}

.ds3-save {
  &:before {
    content: '\e9a1';
  }
}

.ds3-http {
  &:before {
    content: '\e9a5';
  }
}

.ds3-formula {
  &:before {
    content: '\e9a7';
  }
}

.ds3-settings {
  &:before {
    content: '\e9d0';
  }
}

.ds3-key-bind {
  &:before {
    content: '\e9a4';
  }
}

.ds3-text-suspicious {
  &:before {
    content: '\e9e7';
  }
}

.ds3-database {
  &:before {
    content: '\e944';
  }
}

.ds3-forward {
  &:before {
    content: '\e96c';
  }
}

.ds3-unlock {
  &:before {
    content: '\e9ec';
  }
}

.ds3-package {
  &:before {
    content: '\e9af';
  }
}

.ds3-package-up {
  &:before {
    content: '\e9ae';
  }
}

.ds3-screen {
  &:before {
    content: '\e9cb';
  }
}

.ds3-target {
  &:before {
    content: '\e9e6';
  }
}

.ds3-image {
  &:before {
    content: '\e97c';
  }
}

.ds3-pin {
  &:before {
    content: '\e9b6';
  }
}

.ds3-globe {
  &:before {
    content: '\e96e';
  }
}

.ds3-node {
  &:before {
    content: '\e938';
  }
}

.ds3-stat {
  &:before {
    content: '\e9e3';
  }
}

.ds3-user-group {
  &:before {
    content: '\e9f8';
  }
}

.ds3-server {
  &:before {
    content: '\e9cf';
  }
}

.ds3-laptop {
  &:before {
    content: '\e984';
  }
}

.ds3-back {
  &:before {
    content: '\e90a';
  }
}

.ds3-forward-msg {
  &:before {
    content: '\e96b';
  }
}

.ds3-back {
  &:before {
    content: '\e90a';
  }
}
