@use '../theme.scss';

// ---- Input field ----

.input-field {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 34px;

  &:not(.input-width-auto) {
    min-width: 128px;
  }
}

.input-field-default {
  height: 34px;
}

.input-field-big {
  height: 50px;
}

.input-field #clear-button,
.input-field #postfix,
.input-field #prefix {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  z-index: 2;
}

.input-field #prefix {
  left: 8px;
}

.input-field #postfix {
  right: 8px;
}

@mixin base-control-styles {
  @extend .text-main;
  border-radius: 0;
  @include theme.get-color('border', 'color-border', 1px solid);

  &:disabled {
    @include theme.get-color('border', 'color-border', 1px solid);
    @include theme.get-color('background-color', 'color-background-disabled', '', !important);
    @include theme.get-color('color', 'color-primary-disabled', '', !important);
  }

  &:active:not([disabled]),
  &:hover:not([disabled]),
  &:focus-visible:not([disabled]) {
    @include theme.get-color('border', 'color-primary', 1px solid);
    box-shadow: none;
    outline: none;
  }
}

@mixin input-field-default {
  height: 34px;
  padding: 6px 8px;
}

.input-field input {
  @include base-control-styles;

  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  width: 100%;
}

.input-field {
  &:not(.input-width-auto) {
    input {
      min-width: 128px;
    }
  }
}

textarea,
.form-control,
.form-select {
  @include base-control-styles;
  @include input-field-default;
}

textarea.form-control {
  height: auto;
}

.input-field-default input {
  @include input-field-default;
}

.input-field-big input {
  height: 50px;
  padding: 14px 8px;
}

.input-field-error input {
  @include theme.get-color('border', 'color-danger', 1px solid);
}

.input-prefix {
  padding-left: 32px !important;

  input {
    padding-left: 0px;
  }
}

.input-clearable {
  padding-right: 32px !important;

  input {
    padding-right: 0px;
  }
}

.input-postfix {
  padding-right: 32px !important;

  input {
    padding-right: 0px;
  }
}

.input-postfix-clearable {
  padding-right: 56px !important;

  input {
    padding-right: 0px;
  }
}

.input-field #clear-button {
  cursor: pointer;
}

.input-field .input-postfix-clearable ~ #clear-button {
  padding-right: 0 !important;
  right: 32px;
}

.input-field .input-clearable ~ #clear-button {
  right: 8px;
}

.form-control-container {
  .input-label {
    @extend .text-note, .text-m;
    padding-bottom: 4px;
  }
}

.input-group {
  .input-field {
    flex-grow: 1;
  }

  .btn-input-group-suffix {
    @include theme.get-color('border', 'color-border', 1px solid);
    @include theme.get-color('color', 'color-text');

    padding: 0;
    height: 34px;
    background-color: #f8f9fa;
    position: relative;
    z-index: 2;

    &:hover {
      background-color: #f4f4f5;
    }

    i.ds3-icon {
      padding: 8px 12px;
    }
  }
}

.html-value-input {
  border: 1px solid #ccc;
  padding: 4px 8px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.tags-filter-input {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  min-height: 34px;
  height: auto;

  .filter-value-container {
    height: 24px;
  }
}

.no-wrap {
  > .tags-filter-input {
    flex-wrap: nowrap;
  }
}

.prevalence-days-control {
  .input-label {
    .label-icon-container {
      width: 100%;
      justify-content: space-between;
    }
  }
}
.prevalence-state-icon {
  position: absolute;
  top: 0;
  right: 21px;
}

.click-only-input {
  input {
    cursor: pointer;
    caret-color: transparent;
  }
}
