@use 'theme';
@use 'vars';

.search-wrapper {
  border: none !important;

  @media screen and (max-width: 991px) {
    height: 60px;
    padding-left: 0;
  }
}

.search-toggle {
  max-height: 100%;
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.search-bar {
  display: flex;
  align-items: center;
  z-index: 100;
}

.search-form {
  display: flex;
  margin-right: 12px;
  width: 100%;
  border-radius: 0.375rem;
}

.search-bar-input {
  @include theme.get-color('border', 'color-border', 1px solid);
  border-right: 0 !important;
  border-radius: 0;
  width: 250px;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 8px;
  flex-grow: 1;

  @media screen and (max-width: 991px) {
    font-size: 14px;
  }

  @media screen and (max-width: 449px) {
    width: 120px;
  }

  @media screen and (max-width: 389px) {
    width: 100px;
  }
}

.search-bar-input:active,
.search-bar-input:focus {
  @include theme.get-color('border', 'color-primary', 1px solid);
  box-shadow: none;
}

.search-bar-input:focus ~ .search-bar-clear {
  @include theme.get-color('border', 'color-primary', 1px solid);
}

.search-bar-clear {
  @include theme.get-color('border', 'color-border', 1px solid);
  @include theme.get-color('color', 'color-text-muted');
  @include theme.get-color('background-color', 'color-background');
  opacity: 1 !important;
  border-left: none !important;
  border-radius: 0;
  font-size: 13px;
  flex-grow: 0;

  @media screen and (max-width: 991px) {
    font-size: 14px;
  }
}

.search-bar-submit {
  @include theme.get-color('border', 'color-border', 1px solid, !important);
  @include theme.get-color('background-color', 'color-button-background');
  @include theme.get-color('color', 'color-text');
  opacity: 1 !important;
  border-radius: 0 0.375rem 0.375rem 0;
  font-size: 13px;
  flex-grow: 0;

  @media screen and (max-width: 991px) {
    font-size: 14px;
  }
}

.search-bar-submit:hover,
.search-bar-clear:hover {
  @include theme.get-color('background-color', 'color-soft-button-hover-background');
  @include theme.get-color('color', 'color-primary-hover');
}
