@use '../../../../../scss/theme';
@use '../../../../../scss/vars';

.extracted-code {
  white-space: break-spaces;
  @include theme.get-color('color', 'color-text', 1px solid);
  padding: 0;
  font-size: 12px;
  font-family:
    Consolas,
    Monaco,
    Lucida Console,
    Liberation Mono,
    DejaVu Sans Mono,
    Bitstream Vera Sans Mono,
    Courier New;
  max-width: 800px;

  &.with-margins {
    margin-top: 7px;
    margin-left: 25px;
  }

  &.scrollable {
    overflow-y: auto;
    max-height: calc(100vh - 167px);
  }

  &.extractd-code-area {
    border: 1px solid #{vars.$page400};
    padding: 12px 8px;
  }

  pre {
    overflow-x: hidden;
    overflow-y: auto;
    white-space: break-spaces;
  }
}

.extracted-code-font {
  font-size: 12px;
  font-family:
    Consolas,
    Monaco,
    Lucida Console,
    Liberation Mono,
    DejaVu Sans Mono,
    Bitstream Vera Sans Mono,
    Courier New;
}

.exrtacted-code-only-instruction {
  background-color: #{vars.$blue-600};
  padding: 4px 2px;
}
