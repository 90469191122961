@use 'theme';
@use 'vars';

.chart-title {
  text-align: center;
  font-size: 20px;
}

.chart-container,
.text-stat-container {
  @include vars.standard-shadow;
  position: relative;
  padding: 20px;
  margin: 0 0 16px 0;
}

.chart-updated-note,
.stat-updated-note {
  text-align: right;
  font-size: 15px;
  margin-top: 16px;
}

.text-stat-container {
  .data-row {
    > * {
      display: inline-block;
    }
  }
}

.job-state {
  &.success {
    color: rgb(29, 132, 24);
  }
  &.error {
    color: #b40000;
    cursor: pointer;
  }
}

.stat-content-container {
  height: 490px;

  &.height-auto {
    height: auto;
  }
}

.hidden-chart-data {
  margin-top: -24px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  .dropdown-toggle.btn {
    padding: 0;
    background: transparent;
    border: none;
    color: #6c757d;
    font-style: italic;

    &:active {
      background: transparent;
      color: #6c757d;
      border: none;
      box-shadow: none;
    }

    &:focus,
    &:focus-visible {
      border: none;
      box-shadow: none;
    }

    &:active:focus {
      box-shadow: none;
    }

    &:visited {
      text-decoration: none;
    }

    &::after {
      border: none;
    }
  }

  .dropdown-item {
    cursor: default;
    padding: 4px 15px;

    &.disabled {
      color: inherit;
    }

    &:hover {
      color: inherit;
      background-color: inherit;
    }
  }
}

.chart-hidden-data-icon {
  height: 20px;
  width: 20px;
  display: inline-block;
  border-radius: 2px;
  margin-right: 5px;
  vertical-align: bottom;
}
