@use 'theme';
@use 'fonts';

.threat-hunting-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.verdict-checkbox-container {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.search-inputs-container {
  display: flex;
  gap: 20px;
  width: 100%;

  @media screen and (max-width: 991px) {
    flex-direction: column;
  }
}

.search-input-block {
  display: block;
  flex: 1 0 0;
  max-width: 33.33%;

  @media screen and (max-width: 991px) {
    max-width: 100%;
  }
}

.search-inputs-verdict {
  display: block;
  margin-right: 40px; //To compensate the 3rd and 4th element's gap in the next row
  width: 75%;

  @media screen and (max-width: 991px) {
    width: 100%;
  }
}

.search-inputs-date {
  display: block;
  width: 25%;

  @media screen and (max-width: 991px) {
    width: 100%;
  }
}

.search-inputs-verdict-homepage {
  @extend .search-inputs-verdict;
  margin-right: 20px; //To compensate the 3rd element's gap in the next row
  width: 66.67%;
}

.search-inputs-date-homepage {
  @extend .search-inputs-date;
  width: 33.33%;
}

.source-type-input {
  display: flex;
  gap: 4px;
}

.source-type-button {
  @extend .text-note;
  height: 34px;
  line-height: 16px !important;
  font-weight: 400 !important;
  @include theme.get-color('border', 'color-border', 1px solid);
}

.source-type-selected {
  @include theme.get-color('background-color', 'color-background-selected');
  @include theme.get-color('border', 'color-text', 1px solid);

  &:hover {
    @include theme.get-color('background-color', 'color-background-selected');
  }
}

.ioc-search-group {
  margin-top: 40px;
}

.threat-hunting-filters {
  display: flex;
  gap: 20px;
  flex: 1 1 auto;
  flex-wrap: wrap;
}

.filter-chip {
  display: flex;
  gap: 8px;
  align-items: center;
}

.filter-values {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}

.filter-value-container {
  @include theme.get-color('color', 'color-text-muted');
  @include theme.get-color('background-color', 'color-background-selected');
  border-radius: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0;
  cursor: default;

  &:hover {
    @include theme.get-color('color', 'color-text');
    @include theme.get-color('background-color', 'color-hover-background');
  }
}

.filter-name {
  padding: 2px 0;
}

.filter-value {
  border-radius: 0;
  padding: 2px 0;
  padding-left: 8px;
  max-width: 150px;
}

.input-field.no-wrap {
  .filter-value-container {
    gap: 0;
  }
  .filter-value {
    max-width: 60px;

    @media (max-width: 1624px) {
      max-width: 50px;
    }
    @media (max-width: 991px) {
      max-width: 100px;
    }
    @media (max-width: 488px) {
      max-width: 60px;
    }
    @media (max-width: 366px) {
      max-width: 30px;
    }
  }
}

.remove-filter-btn {
  cursor: pointer;
  @include theme.get-color('color', 'color-text-muted');
  padding: 4px;

  &:hover {
    @include theme.get-color('color', 'color-text');
  }
}

.threat-hunting-homepage-button-group {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.threat-hunting-footer {
  margin-top: 40px;
  padding: 20px;
  @include theme.get-color('border-top', 'color-border-divider', 1px solid);
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;

  @media screen and (max-width: 991px) {
    flex-direction: column;
    gap: 40px;
  }
}

.threat-hunting-button-group {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 8px;
  flex: 0 0 auto;

  @media screen and (max-width: 991px) {
    width: 100%;
    justify-content: flex-end;
  }
}
