.header-news {
  margin-top: -20px;
  border-radius: 3px;
  background: url(/assets/imgs/banner/filescan-header.png);
  background-color: #111f42;
  background-size: 100% 100%;
  font-size: 14px;
  line-height: 17px;
  font-family: sans-serif;
  font-weight: 600;
  color: #fff;
  text-align: center;
  padding: 14px 30px 14px;

  a {
    color: #fff;
  }

  @media (max-width: 767px) {
    background-image: none;
  }
}

.header-news span:not(.mobile-block) {
  margin: 0px 15px;
}
