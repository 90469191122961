@use '../vars';

// Redefine Bootstrap 5 spacing

$gutters: (
  0: 0,
  1: #{vars.$gap1},
  2: #{vars.$gap2},
  3: #{vars.$gap3},
  4: #{vars.$gap4},
  5: #{vars.$gap5},
  6: #{vars.$gap6},
  7: #{vars.$gap7},
  8: #{vars.$gap8},
  9: #{vars.$gap9},
  10: #{vars.$gap10},
  11: #{vars.$gap11},
);

@each $key, $value in $gutters {
  // Gutters
  .g-#{ $key } {
    --bs-gutter-x: #{$value};
    --bs-gutter-y: #{$value};
  }
  .gx-#{ $key } {
    --bs-gutter-x: #{$value};
    column-gap: #{$value};
  }
  .gy-#{ $key } {
    --bs-gutter-y: #{$value};
    row-gap: #{$value};
  }
  .gap-#{ $key } {
    gap: #{$value};
  }
  .gap-x-#{ $key } {
    column-gap: #{$value};
  }
  .gap-y-#{ $key } {
    row-gap: #{$value};
  }

  // Margins
  .ms-#{ $key } {
    margin-left: #{$value} !important;
  }
  .me-#{ $key } {
    margin-right: #{$value} !important;
  }
  .mt-#{ $key } {
    margin-top: #{$value} !important;
  }
  .mb-#{ $key } {
    margin-bottom: #{$value} !important;
  }
  .m-#{ $key } {
    margin: #{$value} !important;
  }

  // Paddings
  .ps-#{ $key } {
    padding-left: #{$value} !important;
  }
  .pe-#{ $key } {
    padding-right: #{$value} !important;
  }
  .pt-#{ $key } {
    padding-top: #{$value} !important;
  }
  .pb-#{ $key } {
    padding-bottom: #{$value} !important;
  }
  .py-#{ $key } {
    padding-top: #{$value} !important;
    padding-bottom: #{$value} !important;
  }
  .px-#{ $key } {
    padding-left: #{$value} !important;
    padding-right: #{$value} !important;
  }
  .p-#{ $key } {
    padding: #{$value} !important;
  }
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-bottom: 8px;
}

.width-p-10 {
  width: 10% !important;
}

.width-p-20 {
  width: 20% !important;
}

.width-md-p-15 {
  @media (max-width: 991px) {
    width: 15% !important;
  }
}

.width-sm-p-20 {
  @media (max-width: 767px) {
    width: 20% !important;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: block;
}

.ellipsis-with-icon {
  .ellipsis {
    max-width: 80%;
  }
}
