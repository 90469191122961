@use 'theme';
@use 'fonts';
@use 'vars';

.form-control {
  @extend %general-color;

  &:disabled,
  &[readonly] {
    @include theme.get-color('background-color', 'color-control-disabled');
  }
}

.pagination {
  & .page-item {
    & .page-link {
      @include theme.get-color('color', 'color-primary');
      @include theme.get-color('background-color', 'color-background');
      @include theme.get-color('border-color', 'color-border');
    }

    &.active .page-link {
      @include theme.get-color('color', 'color-white');
      @include theme.get-color('background-color', 'color-primary');
      @include theme.get-color('border-color', 'color-primary');
    }
  }
}

.dropdown-menu .dropdown-item {
  @extend %general-color;
  @extend .text-main, .text-capital;
  padding: 6px 16px;

  &:hover {
    @include theme.get-color('background-color', 'color-nav-hover-background');
    text-decoration: none;
  }

  &:active {
    @include theme.get-color('color', 'color-text');
    @include theme.get-color('background-color', 'color-button-background');
  }
}

.dropdown-no-capitalize {
  .dropdown-menu .dropdown-item {
    text-transform: none !important;
  }
}

.dropdown-menu {
  @extend %general-color;
  border-radius: 0;
  @include theme.get-color('box-shadow', 'color-shadow', 0px 2px 8px);
  @include theme.get-color('border', 'color-border-extra-light', 1px solid);
  padding: 8px 0;
}

.dropdown-divider {
  @include theme.get-color('border-top', 'color-border-extra-light', 1px solid);
}

.list-group {
  border: none;

  & .list-group-item {
    @extend %general-color;
    @include theme.get-color('border-color', 'color-border-lightest');
  }
}
.list-group-item.list-group-item-action {
  &:hover {
    @include theme.get-color('background-color', 'color-hover-background');
  }

  &:active {
    @include theme.get-color('background-color', 'color-vertical-menu-item-active');
    @include theme.get-color('border-color', 'color-vertical-menu-item-active-border');
  }

  &.active {
    @include theme.get-color('color', 'color-list-item-active');
    @include theme.get-color('background-color', 'color-light');

    .card & {
      @include theme.get-color('background-color', 'color-primary');
      @include theme.get-color('border-color', 'color-primary');

      &:hover {
        @include theme.get-color('color', 'color-list-item-active-hover');
        @include theme.get-color('background-color', 'color-primary');
        @include theme.get-color('border-color', 'color-primary');
      }
    }
  }
}

.modal-dialog .modal-content,
.modal-dialog .modal-header,
.card {
  @extend %general-color;
  @include theme.get-color('border-color', 'color-border');

  & .card-header {
    @include theme.get-color('border-color', 'color-border');
  }
}

.close {
  @include theme.get-color('color', 'color-close-btn');
  @include theme.get-color('text-shadow', 'color-close-btn-shadow', 0 1px 0);
}

.nav-tabs {
  & .nav-link {
    @include theme.get-color('color', 'color-primary');

    &:hover {
      @include theme.get-color('color', 'color-primary-hover');
      @include theme.get-color('border-color', 'color-border');
    }

    &.active {
      @include theme.get-color('background-color', transparent);
      @include theme.get-color('border-color', 'color-border');
      @include theme.get-color('border-bottom-color', 'color-background');
      @include theme.get-color('color', 'color-text');
    }
  }
}

.dropdownlist-container {
  @extend %general-color;
  @include theme.get-color('border-color', 'color-border');
}

.rw-widget {
  & input {
    border: none;
  }

  & .rw-widget-container {
    @include theme.get-color('border-color', 'color-border');
  }

  & .rw-popup {
    @include theme.get-color('background-color', 'color-background');
    @include theme.get-color('border-color', 'color-border');
    margin: -1px;

    & .rw-list-optgroup {
      @include theme.get-color('border-color', 'color-border');
    }

    & .rw-list-option {
      @extend %general-color;
      // border: none;

      &:hover {
        border: none;
        @include theme.get-color('background-color', 'color-hover-background');
      }
    }
  }

  & .rw-multiselect-taglist {
    @include theme.get-color('background-color', 'color-background');
  }

  & .rw-multiselect-tag {
    border: none;
  }
}

.rw-picker-caret.rw-btn {
  @extend %general-color;
}

.btn-light {
  @include theme.get-color('color', 'color-text');
  @include theme.get-color('background-color', 'color-light');
}

.switch-handle {
  @include theme.get-color('background-color', 'color-border');
}

.text-muted {
  @include theme.get-color('color', 'color-text-muted', '', !important);
}

.border-bottom {
  @include theme.get-color('border-bottom', 'color-border-lightest', 1px solid, !important);
}

.input-group-append .input-group-text {
  @extend %general-color;
  @include theme.get-color('border-color', 'color-border');
}

[data-custom-class='title'],
[data-custom-class='title'] * {
  @include theme.get-color('color', 'color-custom-title', '', !important);
}

[data-custom-class='subtitle'],
[data-custom-class='subtitle'] * {
  @include theme.get-color('color', 'color-custom-subtitle', '', !important);
}

[data-custom-class='body_text'],
[data-custom-class='body_text'] * {
  @include theme.get-color('color', 'color-custom-body', '', !important);
}

[data-custom-class='heading_1'],
[data-custom-class='heading_1'] *,
[data-custom-class='heading_2'],
[data-custom-class='heading_2'] * {
  @include theme.get-color('color', 'color-custom-title', '', !important);
}

.rd3t-link {
  @include theme.get-color('stroke', 'color-border');
  stroke-width: 2;
}

.collapse-highlighted-content > pre {
  @include theme.get-color('color', 'color-text', '', !important);
}

.accordion-button {
  box-shadow: none !important;
  @include theme.get-color('color', 'color-text');
}

// ---- Buttons DS3.0 ----

.btn {
  @extend .text-main, .text-m;
  border-radius: 0;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  width: fit-content;
  text-wrap: nowrap;
}

.btn-primary {
  @include theme.get-color('color', 'color-white');
  @include theme.get-color('background-color', 'color-primary');
  @include theme.get-color('border', 'color-primary', 1px solid);

  &:hover {
    @include theme.get-color('background-color', 'color-primary-hover');
  }

  &.disabled,
  &:disabled {
    @include theme.get-color('color', 'color-white');
    @include theme.get-color('background-color', 'color-primary-disabled');
    @include theme.get-color('border', 'color-primary-disabled', 1px solid);
  }
}

.btn-secondary {
  @include theme.get-color('border', 'color-text', 1px solid);
  @include theme.get-color('color', 'color-text');
  @include theme.get-color('background-color', 'color-background');

  &:hover {
    @include theme.get-color('border', 'color-primary-hover', 1px solid);
    @include theme.get-color('color', 'color-primary-hover');
    @include theme.get-color('background-color', 'color-background');
  }

  &.disabled,
  &:disabled {
    @include theme.get-color('border', 'color-primary-disabled', 1px solid);
    @include theme.get-color('color', 'color-primary-disabled');
    @include theme.get-color('background-color', 'color-background');
  }
}

.btn-tertiary {
  @extend %general-color;
  border: none !important;
  padding: 6px 0;

  &:hover {
    @include theme.get-color('color', 'color-primary-hover');
  }

  &.disabled,
  &:disabled {
    @include theme.get-color('color', 'color-primary-text-disabled');
    background-color: transparent !important;
  }
}

.btn-danger {
  @include theme.get-color('border', 'color-danger', 1px solid);
  @include theme.get-color('color', 'color-white');
  @include theme.get-color('background-color', 'color-danger');

  &:hover {
    @include theme.get-color('border', 'color-danger-hover', 1px solid);
    @include theme.get-color('color', 'color-white');
    @include theme.get-color('background-color', 'color-danger-hover');
  }

  &.disabled,
  &:disabled {
    @include theme.get-color('border', 'color-danger-disabled', 1px solid);
    @include theme.get-color('color', 'color-white');
    @include theme.get-color('background-color', 'color-danger-disabled');
  }
}

.dropdown-toggle {
  &:after {
    @include fonts.icon-font;

    border: none;
    margin-left: 4px;
    vertical-align: text-bottom;
    content: '\e9dc';
  }
}

.btn-close {
  --bs-btn-close-bg: none;
  --bs-btn-close-opacity: 1;

  &:before {
    @include fonts.icon-font;

    content: '\e933';
  }
}

.text-secondary {
  color: inherit !important;
}

.text-warning {
  color: #{vars.$orange100} !important;
}

.invalid-feedback {
  font-size: 12px;
  font-weight: 400;
  @include theme.get-color('color', 'color-malicious');
}

p {
  margin-bottom: 16px;
}
