@use '../../../../../scss/theme';

.extracted-string {
  @include theme.get-color('border-bottom', 'color-table-border', 1px solid);
  padding: 8px 16px;

  .react-json-view {
    margin-top: 10px;
  }

  .value {
    .long-string {
      display: inline-block;
    }
  }
}

.strings-files-links {
  display: flex;
  margin-bottom: 15px;

  .strings-file-select-link {
    max-width: 141px;
    overflow: hidden;
    word-wrap: normal;
    text-overflow: ellipsis;
    margin: 10px 15px 0 0;
  }
}

.string-mark {
  margin-right: 0px;
  margin-left: -15px;
  color: #1e88e5;
  padding: 0 5px;
}

.toggle-meta {
  margin-left: 10px;
  display: inline-block;
}

.string-meta-preview {
  margin-top: 10px;
}

.extracted-string-export {
  padding: 12px 16px;
  @include theme.get-color('border-bottom', 'color-table-border', 1px solid);
}
