@use '../vars';
@use '../theme';

table {
  @extend %general-color;
  @include theme.get-color('border-color', 'color-table-border');
}

.table-bordered th,
.table-bordered td {
  @include theme.get-color('border-color', 'color-table-border');
}

.table {
  width: 100%;

  tr {
    border-bottom: 1px solid #{vars.$page200};

    td,
    th {
      padding: 10px 8px;
    }
  }

  &.medium-size {
    tr {
      td,
      th {
        padding: 8px 8px;
      }
    }
  }

  &.small-size {
    tr {
      td,
      th {
        padding: 2px 8px;
      }
    }
  }

  &.left-aligned {
    tr {
      td:first-child,
      th:first-child {
        padding-left: 0;
      }
    }
  }

  &.no-border {
    tr,
    th,
    td {
      border: none;
    }
  }

  &.border-lightest {
    tr {
      @include theme.get-color('border-bottom', 'color-border-lightest', 1px solid);
    }
  }

  // Redefine BS5 style
  > :not(caption) > * > * {
    padding: inherit;
    @include theme.get-color('color', 'color-text');
  }
}

table.empty-header {
  tr {
    th {
      padding: 0 !important;
    }
  }
}

th.icon-col {
  width: 32px;
  text-wrap: nowrap;
}

.custom-table {
  display: table;

  .custom-table-row {
    display: table-row;

    .custom-table-cell {
      display: table-cell;
    }
  }
}

.downloads-table {
  th {
    width: 12%;
  }

  th:first-child {
    width: 50%;
  }
  th:nth-child(2) {
    width: 17%;
  }
}
