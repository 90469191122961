@use '../vars';
@use '../theme';

.nav.nav-tabs {
  border-bottom: 1px solid #{vars.$page200} !important;

  > .nav-item {
    > .nav-link {
      @extend .small-header;

      border: none;
      margin: 2px 28px 0 0;
      padding: 4px 0 4px 0;
      line-height: 21px;
      color: #{vars.$page700} !important;

      &.active {
        border: none;
        padding-bottom: 2px;
        color: #{vars.$page1100} !important;
        border-bottom: 2px solid #{vars.$page1100} !important;
      }

      &:hover {
        border: none;
      }
    }

    &:last-child {
      > .nav-link {
        margin-right: 0;
      }
    }
  }
}

.tabs-center {
  .nav.nav-tabs {
    width: 100%;
    justify-content: center;
  }

  .tab-content {
    width: 100%;
  }
}

.embedded-right-tabs,
.embedded-center-tabs {
  .nav.nav-tabs {
    justify-content: right;
    margin-top: -47px;
    border-color: transparent !important;

    .nav-link.active,
    .nav-item.show .nav-link,
    .nav-link:hover,
    .nav-link:focus {
      border-color: transparent;
    }
  }

  &.small-tabs {
    .nav.nav-tabs {
      margin-top: -40px;

      .nav-link {
        padding-left: 4px;
        padding-right: 4px;
      }

      .nav-item:last-of-type {
        .nav-link {
          padding-right: 0;
        }
      }
    }
  }

  &.medium-tabs {
    .nav-link {
      padding-left: 9px;
      padding-right: 9px;
    }
  }

  &.note-header {
    .nav.nav-tabs {
      margin-top: -33px;
    }
  }

  &.no-header {
    .nav.nav-tabs {
      margin-top: 0;
    }
  }
}

.embedded-center-tabs {
  .nav.nav-tabs {
    justify-content: center;
    border-bottom: none;
  }
}

.nav-tabs {
  > .nav-item {
    > .nav-link {
      word-break: break-all;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 250px;

      > span > .nav-link-label {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-all;
      }
    }
  }
}

.tab-content > .tab-pane {
  padding: 20px 0 16px 0;
}

.small-tabs {
  > .nav-tabs {
    > .nav-item {
      > .nav-link {
        font-size: 13px;
        margin-top: 0;
        padding-top: 0;
      }
    }
  }
}
