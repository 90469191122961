@use '../vars.scss';
@use '../theme.scss';
@use '../fonts.scss';
@use 'text-input.scss';

.multi-select__dropdown-indicator {
  padding: 4px 8px !important;

  &::before {
    @include fonts.icon-font;
    content: '\e924';
    color: #{vars.$page1100};
    cursor: pointer;

    &:hover {
      color: #{vars.$page1100};
    }
  }

  > * {
    display: none !important;
  }
}

.rw-multiselect {
  > .rw-widget-input {
    @include text-input.base-control-styles;

    max-height: none;
    min-height: 34px;

    .rw-multiselect-tag {
      min-height: calc(33px * 0.77);
      @include theme.get-color('background-color', 'color-primary', '', !important);
      @include theme.get-color('color', 'color-white', '', !important);
      border-radius: 0;

      .rw-multiselect-tag-btn {
        line-height: 25px;
      }
    }
  }

  .rw-list-option {
    padding: 8px 8px;
  }
}

.rw-widget {
  padding: 0;
  height: auto;

  .rw-list-option {
    font-size: 14px;
  }

  .rw-multiselect-input {
    width: auto;
    height: calc(34px - 1px * 2);
  }
  .rw-multiselect-input[value=''] {
    width: 0;
  }

  &.with-placeholder {
    .rw-multiselect-input[value=''] {
      width: auto;
    }

    .rw-multiselect-tag + .rw-multiselect-input[value=''] {
      width: 0;
    }
  }
}

.rw-state-focus .rw-widget-picker.rw-widget-input {
  box-shadow: none;
}
