@use 'theme.scss';

.yara-search-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
}

.yara-search-filter {
  display: flex;
  gap: 20px;
  padding: 20px;
  @include theme.get-color('border-bottom', 'color-border-lightest', 1px solid);

  .dropdown-item {
    text-transform: none !important;
  }

  @media screen and (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;

    & > div {
      width: 100%;
    }
  }
}

.yara-search-cb-placeholder {
  min-height: 24px;
  padding-bottom: 4px;
  margin: 0;
}

.yara-search-body {
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
  display: flex;
  margin: 0 20px;
  @include theme.get-color('border', 'color-border-lightest', 1px solid);

  & > div:not(:last-child) {
    @include theme.get-color('border-right', 'color-border-lightest', 1px solid);
  }

  @media screen and (max-width: 991px) {
    border: none !important;
    margin: 0;

    & > div {
      border-right: none !important;
      overflow-y: auto;
      flex-grow: 1;
    }
  }
}

.yara-rules-list {
  max-width: 300px;
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 991px) {
    max-width: 100%;
    width: 100%;
  }
}

.yara-rules-list-wrapper {
  flex-grow: 1;
  height: 100%;
  overflow-y: auto;
}

.yara-rules-list-element {
  @include theme.get-color('border-bottom', 'color-border-lightest', 1px solid);
  @include theme.get-color('background-color', 'color-soft-button-hover-background');
  max-width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;

  & > div {
    padding: 10px 8px;
  }

  &.selected {
    @include theme.get-color('background-color', 'color-background', '', !important);
  }
}

.yara-rule-name {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

// Unused for now
// .yara-rule-number {
//   @include theme.get-color("background", "color-border");
//   padding: 0 4px !important;
//   margin: 4px 8px;
// }

.yara-rules-list-header {
  padding: 10px 8px;
  @include theme.get-color('border-bottom', 'color-border-lightest', 1px solid);
}

.yara-rule-content {
  flex-grow: 1;
  width: 60%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.yara-rule-details {
  padding: 20px;
  width: 20%;
  overflow-y: auto;
}
