@use '../theme';
@use '../vars';
@use '../icons';

.collapse-with-icon {
  display: flex;

  .collapse-icon {
    @include vars.standard-shadow;
    width: 50px;
    min-width: 50px;
    height: 50px;
    margin-right: 10px;
    border: 5px solid white;
  }

  > .accordion {
    flex-grow: 1;
  }

  &:last-of-type {
    .collapse-icon-container {
      &.icon-timeline {
        &:after {
          display: none;
        }
      }
    }
  }
}

.collapse-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  @include theme.get-color('color', 'color-white');

  &.icon-chat-gpt {
    background: #5da182;
  }
  &.icon-blue {
    background: #{vars.$blue};

    .ds3-category-default {
      @extend .ds3-play;
    }
  }
  &.icon-raspberry {
    background: #{vars.$raspberry};

    .ds3-category-default {
      @extend .ds3-file-blank;
    }
  }
  &.icon-teal-blue {
    background: #{vars.$teal-blue};

    .ds3-category-default {
      @extend .ds3-globe;
    }
  }
  &.icon-purple {
    background: #{vars.$purple};

    .ds3-category-default {
      @extend .ds3-node;
    }
  }
  &.icon-grey {
    background: #{vars.$page900};

    .ds3-category-default {
      @extend .ds3-pin;
    }
  }
}

.collapse-icon-container {
  position: relative;

  &.icon-timeline {
    &:after {
      content: '';
      display: block;
      width: 4px;
      height: 100%;
      background-color: #{vars.$page300};
      left: 23px;
      position: absolute;
    }
  }
}
