@use '../theme.scss';

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(27, 39, 60, 0.8);
  z-index: 99;
  display: block;
  cursor: default;
  pointer-events: none;
}

.dialog-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  z-index: 100;
}

.dialog-full-page {
  width: 100%;
  height: 100%;
  display: flex;
}

.dialog-card-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 100%;
}

.dialog-header {
  display: flex;
  min-height: 64px;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  @include theme.get-color('border-bottom', 'color-border-lightest', 1px solid);
}

.dialog-title {
  display: flex;
  gap: 10px;
  align-items: center;

  @media screen and (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.dialog-body {
  flex-grow: 1;
  max-height: calc(100% - 72px - 64px - 20px); // exclude header, footer, gap
  padding: 0 !important;
  border: none !important;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 991px) {
    max-height: calc(100% - 120px - 64px - 20px); // exclude header, footer, gap
  }
}

.dialog-footer {
  min-height: 72px;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-top: 20px;
  gap: 20px;
  @include theme.get-color('border-top', 'color-border-lightest', 1px solid);

  @media screen and (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
    min-height: 120px;
  }
}

.dialog-filter-label {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
}

.dialog-filter-summary {
  display: flex;
  gap: 16px;
  align-items: center;
  overflow-x: auto;
  min-height: 32px;

  .filter-name {
    flex: 0 0 auto;
  }

  .filter-values {
    max-width: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex: 1 1 auto;
  }

  @media screen and (max-width: 991px) {
    max-width: 100%;
  }
}

.dialog-button-group {
  display: flex;
  gap: 8px;
  flex: 0 0 auto;
}
