.extended-details {
  margin-top: -9px;
  z-index: 1;
  position: relative;
  box-shadow: cadetblue;
  -webkit-box-shadow: 0px -6px 17px -1px rgba(34, 60, 80, 0.14) inset;
  -moz-box-shadow: 0px -6px 17px -1px rgba(34, 60, 80, 0.14) inset;
  box-shadow: 0px -6px 17px -1px rgba(34, 60, 80, 0.14) inset;
  margin-bottom: -8px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.request-type-badge {
  display: inline-block;
  border-radius: 4px;
  padding: 0px 7px;
  border-left-width: 4px;
  border-left-style: solid;
  text-transform: lowercase;
  opacity: 0.9;

  // default coloring, applies also to types we do not know about yet
  background-color: #78909c;
  border-left-color: #455a64;
  color: white;

  &.request-script {
    background-color: orange;
    border-left-color: rgb(255, 123, 0); //#ffab40
    color: inherit;
  }
  &.request-xhr {
    background-color: rgb(204, 0, 158);
    border-left-color: rgb(146, 0, 114);
    color: white;
  }
  &.request-font {
    background-color: #7e57c2;
    border-left-color: #512da8;
    color: white;
  }
  &.request-document {
    background-color: #009688;
    border-left-color: #00695c;
    color: white;
  }
  &.request-image {
    background-color: #039be5;
    border-left-color: #0277bd;
    color: white;
  }
  &.request-stylesheet {
    background-color: #9ccc65;
    border-left-color: #689f38;
    color: inherit;
  }
  &.request-fetch {
    background-color: #1565c0;
    border-left-color: #0d47a1;
    color: white;
  }
  &.request-media {
    background-color: #bbdefb;
    border-left-color: #64b5f6;
    color: inherit;
  }
  &.request-preflight {
    background-color: white;
    color: inherit;
    border: 1px solid rgb(200, 204, 208);
    border-left: 4px solid rgb(133, 143, 156);
  }
}

.filters-contaier {
  @media (max-width: 700px) {
    flex-direction: column;
  }
}
