.import-name {
  color: rgb(151, 44, 2);
}

#file-details-page {
  .overview-by-sections {
    > .data-block {
      > .data-row {
        > .label {
          width: 280px;

          @media (max-width: 991px) {
            width: 150px;
          }
          @media (max-width: 750px) {
            width: auto;
          }
        }
      }
    }
  }
}
