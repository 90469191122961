@use '../theme.scss';
@use '../icons.scss';
@use '../fonts.scss';

// ---- Checkbox ----

.checkbox-component-container {
  display: flex;
  align-items: center;
  cursor: pointer;

  > .checkbox-wrapper {
    display: flex;
    align-items: center;
  }
}

.checkbox-container {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  padding: 2px 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

/* Show custom checkbox */
.checkbox-custom-input {
  position: absolute;
  height: 16px;
  width: 16px;
  top: 0px;
  left: 0;
  padding: 0;
  @include theme.get-color('background-color', 'color-background');
  @include theme.get-color('border-color', 'color-border');
  border-width: 1px;
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Custom checkmark, hidden if not checked  */
  &::before {
    content: '';
    display: none;
    width: 12px;
    height: 12px;
    background: url('/assets/icons/opswat/Check.svg') no-repeat;
    background-size: contain;
    filter: invert(100%) sepia(93%) saturate(163%) hue-rotate(262deg) brightness(108%) contrast(107%);
  }

  &.radio {
    border-radius: 50%;
    @include theme.get-color('background-color', 'color-white', '', !important);

    // Redefine values of checkbox
    &::before {
      content: '';
      display: none;
      width: 0;
      height: 0;
      background: none;
    }
  }
}

.checkbox-component-container.partial-checked {
  .checkbox-custom-input {
    @extend .ds3-icon, .ds3-dash;

    &::before {
      @include theme.get-color('color', 'color-white');
      filter: none;
      background: none;
      top: -4px;
      right: 2px;
    }
  }
}

.checkbox-component-container input:checked ~ .checkbox-custom-input.radio {
  border-width: 5px !important;
}

/* On mouse-over */
.checkbox-component-container:hover input ~ .checkbox-custom-input.primary,
.checkbox-component-container:hover input ~ .checkbox-custom-input.benign {
  @include theme.get-color('border-color', 'color-primary-hover');
}

.checkbox-component-container:hover input ~ .checkbox-custom-input.unknown {
  @include theme.get-color('border-color', 'color-unknown-hover');
}

.checkbox-component-container:hover input ~ .checkbox-custom-input.informational,
.checkbox-component-container:hover input ~ .checkbox-custom-input.no_threat {
  @include theme.get-color('border-color', 'color-no_threat-hover');
}

.checkbox-component-container:hover input ~ .checkbox-custom-input.suspicious {
  @include theme.get-color('border-color', 'color-suspicious-hover');
}

.checkbox-component-container:hover input ~ .checkbox-custom-input.likely_malicious {
  @include theme.get-color('border-color', 'color-likely_malicious-hover');
}

.checkbox-component-container:hover input ~ .checkbox-custom-input.malicious {
  @include theme.get-color('border-color', 'color-malicious-hover');
}

.checkbox-component-container:hover input ~ .checkbox-custom-input.dark {
  @include theme.get-color('border-color', 'color-unknown-hover');
}

/* Checkbox checked */
.checkbox-component-container input:checked ~ .checkbox-custom-input.primary,
.checkbox-component-container input:checked ~ .checkbox-custom-input.benign {
  @include theme.get-color('background-color', 'color-primary');
  @include theme.get-color('border-color', 'color-primary');
}

.checkbox-component-container input:checked ~ .checkbox-custom-input.unknown {
  @include theme.get-color('background-color', 'color-unknown');
  @include theme.get-color('border-color', 'color-unknown');
}

.checkbox-component-container input:checked ~ .checkbox-custom-input.informational,
.checkbox-component-container input:checked ~ .checkbox-custom-input.no_threat {
  @include theme.get-color('background-color', 'color-no_threat');
  @include theme.get-color('border-color', 'color-no_threat');
}

.checkbox-component-container input:checked ~ .checkbox-custom-input.suspicious {
  @include theme.get-color('background-color', 'color-suspicious');
  @include theme.get-color('border-color', 'color-suspicious');
}

.checkbox-component-container input:checked ~ .checkbox-custom-input.likely_malicious {
  @include theme.get-color('background-color', 'color-likely_malicious');
  @include theme.get-color('border-color', 'color-likely_malicious');
}

.checkbox-component-container input:checked ~ .checkbox-custom-input.malicious {
  @include theme.get-color('background-color', 'color-malicious');
  @include theme.get-color('border-color', 'color-malicious');
}

.checkbox-component-container input:checked ~ .checkbox-custom-input.dark {
  @include theme.get-color('background-color', 'color-unknown');
  @include theme.get-color('border-color', 'color-unknown');
}

/* Checked + mouse hover */
.checkbox-component-container:hover input:checked ~ .checkbox-custom-input.primary,
.checkbox-component-container:hover input:checked ~ .checkbox-custom-input.benign {
  @include theme.get-color('background-color', 'color-primary-hover');
  @include theme.get-color('border-color', 'color-primary-hover');
}

.checkbox-component-container:hover input:checked ~ .checkbox-custom-input.unknown {
  @include theme.get-color('background-color', 'color-unknown-hover');
  @include theme.get-color('border-color', 'color-unknown-hover');
}

.checkbox-component-container:hover input:checked ~ .checkbox-custom-input.informational,
.checkbox-component-container:hover input:checked ~ .checkbox-custom-input.no_threat {
  @include theme.get-color('background-color', 'color-no_threat-hover');
  @include theme.get-color('border-color', 'color-no_threat-hover');
}

.checkbox-component-container:hover input:checked ~ .checkbox-custom-input.suspicious {
  @include theme.get-color('background-color', 'color-suspicious-hover');
  @include theme.get-color('border-color', 'color-suspicious-hover');
}

.checkbox-component-container:hover input:checked ~ .checkbox-custom-input.likely_malicious {
  @include theme.get-color('background-color', 'color-likely_malicious-hover');
  @include theme.get-color('border-color', 'color-likely_malicious-hover');
}

.checkbox-component-container:hover input:checked ~ .checkbox-custom-input.malicious {
  @include theme.get-color('background-color', 'color-malicious-hover');
  @include theme.get-color('border-color', 'color-malicious-hover');
}

.checkbox-component-container:hover input:checked ~ .checkbox-custom-input.dark {
  @include theme.get-color('background-color', 'color-unknown-hover');
  @include theme.get-color('border-color', 'color-unknown-hover');
}

/* Disabled */
.checkbox-component-container input:disabled ~ .checkbox-custom-input {
  @include theme.get-color('background-color', 'color-background-disabled');
  @include theme.get-color('border-color', 'color-border-disabled');
}

/* Disabled + checked */
.checkbox-component-container input:checked:disabled ~ .checkbox-custom-input {
  @include theme.get-color('background-color', 'color-border-disabled', '', !important);
  @include theme.get-color('border-color', 'color-border-disabled', '', !important);
}

/* Default cursor if input is disabled */
.checkbox-component-container:has(> .checkbox-container > input:disabled) {
  cursor: default !important;
  pointer-events: none !important;
}

/* Show the checkmark when checked */
.checkbox-component-container input:checked ~ .checkbox-custom-input::before {
  display: block;
}
