@use 'fonts';

$top-menu-height: 80px;
$top-menu-height-mobile: 60px;
$top-menu-m-b: 20px;
$top-menu-total-h: calc(#{$top-menu-height} + #{$top-menu-m-b});
$footer-h: 105px;
$footer-h-mobile: 275px;

$main-container-p-t: 30px;
$main-container-p-b: 44px;
$main-container-p-x: 20px;

$main-container-p-t-mobile: 20px;
$main-container-p-b-mobile: 26px;
$main-container-p-x-mobile: 20px;

$footerMaxWidth: 490px;
$reportPageMaxWidth: 1198px;
$reportPageMinWidth: 574px;

// ---- Colors DS3.0 ----

// Page colors
$page100: #f4f4f5;
$page200: #e9eaeb;
$page300: #d2d4d6;
$page400: #bcbfc3;
$page500: #a4a8ae;
$page600: #8e939b;
$page700: #707682;
$page800: #616875;
$page900: #485161;
$page1000: #313c4e;
$page1100: #1b273c;
$page1200: #141e2f;
$page1300: #141e2f;

// Left Navigation
$nav100: #f1f3f8;
$nav200: #e4e9f5;
$nav300: #dbe1f0;
$nav400: #d3daeb;
$nav500: #cbd3e7;
$nav600: #c3cce2;

// Main Colors
$blue-650: #eff4ff;
$blue-600: #e1e9fe;
$blue-500: #bed5fe;
$blue-400: #9ec0fe;
$blue-300: #7eaafd;
$blue-200: #5e95fd;
$blue-100: #3d80fc;
$blue: #1d6bfc;
$blue100: #195ddb;
$blue200: #154fba;
$blue300: #124098;
$blue400: #0e3277;
$blue500: #0a2456;
$blue600: #061635;
$blue700: #020914;

$red-650: #fceded;
$red-600: #f8dbdb;
$red-500: #f2b7b6;
$red-400: #eb9392;
$red-300: #e46f6d;
$red-200: #de4b49;
$red-100: #d72724;
$red: #d00300;
$red100: #b50300;
$red200: #990200;
$red300: #7e0200;
$red400: #620100;
$red500: #470100;
$red600: #2c0100;
$red700: #110000;

$orange-650: #fef4ed;
$orange-600: #fce9db;
$orange-500: #fad4b8;
$orange-400: #fad4b8;
$orange-300: #f5a871;
$orange-200: #f2924d;
$orange-100: #f07d2a;
$orange: #ed6706;
$orange100: #ce5905;
$orange200: #af4c04;
$orange300: #8f3e04;
$orange400: #703103;
$orange500: #512302;
$orange600: #321601;
$orange700: #130800;

$yellow-650: #fffaee;
$yellow-600: #fff6dc;
$yellow-500: #fff6dc;
$yellow-400: #fee397;
$yellow-300: #fed975;
$yellow-200: #fed052;
$yellow-100: #fdc630;
$yellow: #fdbd0d;
$yellow100: #dca40b;
$yellow200: #ba8b09;
$yellow300: #997208;
$yellow400: #775906;
$yellow500: #564004;
$yellow600: #352803;
$yellow700: #140f01;

$green-650: #edf7ed;
$green-600: #dbeedb;
$green-500: #b6deb6;
$green-400: #92cd92;
$green-300: #6dbc6d;
$green-200: #49ab49;
$green-100: #49ab49;
$green: #008a00;
$green100: #007800;
$green200: #006600;
$green300: #005300;
$green400: #004100;
$green500: #002f00;
$green600: #001d00;
$green700: #000b00;

// Secondary colors
$teal-blue-650: #eef6f8;
$teal-blue-600: #deeef0;
$teal-blue-500: #b9dcdf;
$teal-blue-400: #95cbd1;
$teal-blue-300: #72b9c1;
$teal-blue-200: #4aa7b1;
$teal-blue-100: #1a96a1;
$teal-blue: #178594;
$teal-blue100: #007480;
$teal-blue200: #00626d;
$teal-blue300: #005058;
$teal-blue400: #003f45;
$teal-blue500: #002d31;
$teal-blue600: #011c1f;
$teal-blue700: #010c0d;

$teal-green-650: #eef9f6;
$teal-green-600: #dcf3ed;
$teal-green-500: #b6e6da;
$teal-green-400: #92dac7;
$teal-green-300: #6ecdb5;
$teal-green-200: #42c0a1;
$teal-green-100: #00b48f;
$teal-green: #15a87c;
$teal-green100: #00926d;
$teal-green200: #007c5d;
$teal-green300: #00654b;
$teal-green400: #004f3a;
$teal-green500: #00392a;
$teal-green600: #00231a;
$teal-green700: #000f0b;

$purple-650: #f7f1fd;
$purple-600: #ede2f9;
$purple-500: #dcc4f3;
$purple-400: #c9a7ed;
$purple-300: #b78ae8;
$purple-200: #a46ce3;
$purple-100: #9350de;
$purple: #7e32dd;
$purple100: #702ebc;
$purple200: #5f26a0;
$purple300: #4e1f82;
$purple400: #3c1866;
$purple500: #2c114a;
$purple600: #1b0a2d;
$purple700: #0c0414;

$raspberry-650: #fbf1f6;
$raspberry-600: #f7e2ec;
$raspberry-500: #ecc3da;
$raspberry-400: #e3a6c7;
$raspberry-300: #da89b5;
$raspberry-200: #cf6ba1;
$raspberry-100: #c54e90;
$raspberry: #b4307f;
$raspberry100: #a32a6d;
$raspberry200: #8a255d;
$raspberry300: #701d4c;
$raspberry400: #59183b;
$raspberry500: #40102a;
$raspberry600: #280a1b;
$raspberry700: #11040b;

// Malicious scale
//yellow
$yellow1: #f9a80b;
$yellow2: #f59209;
$yellow3: #f17c08;
//orange
$orange1: #e64e05;
$orange2: #de3503;
$orange3: #d71b01;
//red

// ---- Fonts DS3.0 ----
$body-font-size: 14px;
$secondary-content-font-size: 13px;

// ---- Spacing DS3.0 ----
$gap1: 4px;
$gap2: 8px;
$gap3: 12px;
$gap4: 16px;
$gap5: 20px;
$gap6: 24px;
$gap7: 28px;
$gap8: 32px;
$gap9: 40px;
$gap10: 44px;
$gap11: 48px;

@mixin standard-shadow {
  box-shadow: 0px 2px 8px 0px rgba(10, 36, 86, 0.15);
  border-width: 0px;
}

@mixin standard-shadow-hover {
  &:hover {
    box-shadow: 0px 2px 8px 0px rgba(10, 36, 86, 0.3);
  }
}

.navbar-nav .nav-link {
  --bs-navbar-nav-link-padding-x: 0;
}

.btn {
  &.fixed {
    height: 34px;
  }

  &:disabled {
    color: $page200;
    background-color: $page500;
  }
}
