@use 'theme';
@use 'vars';
@use 'ds3-elements/table';

.admin-top-menu-links-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 90%;

  @media (max-width: 1600px) {
    max-width: 100%;
  }

  a {
    color: inherit !important;

    &:last-child {
      margin-right: 0 !important;
    }
  }
}

.admin-container {
  margin-left: auto;
  margin-right: auto;
}

.admin-top-menu {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -32px;
}

.admin-side-menu {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

table.admin-table {
  @extend .table;
}

table.admin-table,
table.table-with-controls {
  .table-item-control {
    cursor: pointer;
    opacity: 0.4;
    display: flex;
    justify-content: center;

    &.delete-item {
      color: #dc3545;
    }

    &.edit-item {
      color: inherit;
    }

    &:hover {
      opacity: 1;
    }
  }

  .spinner-border {
    width: 15px;
    height: 15px;
    border-width: 3px;
  }
}

.remote-select-container {
  position: relative;

  .spinner-border {
    opacity: 0.6;
    position: absolute;
    left: 13px;
    top: 11px;
  }
}

.admin-panel-title {
  font-weight: bold;
  font-size: 13px;
  margin-left: 12px;
  @include theme.get-color('color', 'color-text-muted');

  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
}

.admin-submenu {
  min-height: 45px;
  margin-top: -8px;
  padding-top: 4px;

  @include theme.get-color('background-color', 'color-nav-background');
}

.admin-pagination-container {
  margin-top: 30px;
}

.edit-icon-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & .edit-icon {
    @include theme.get-color('color', 'color-edit-icon');

    &:hover {
      cursor: pointer;
    }
  }
}

.admin-filter {
  width: 400px;

  @media (max-width: 750px) {
    width: auto;
  }

  .input-clear-icon {
    position: absolute;
    right: 17px;
    top: 10px;
    font-size: 19px;
    cursor: pointer;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
}

.input-outside-loader {
  width: 20px;
  height: 17px;
  border-width: 3px;
  margin-right: -26px;
  border-color: rgb(186, 192, 198);
  border-right-color: transparent;
}

.filter-counter {
  height: 38px;
  align-items: center;
  padding-top: 4px;
}

.log-message {
  &.message-success {
    color: green;
  }
  &.message-error {
    color: #dc3545;
  }
}

.logo-table {
  img.logo-selector,
  .logo-container {
    &:hover {
      @include theme.get-color('background', 'color-background-logo-picker-hover');
      cursor: pointer;
      border-radius: 4px;
    }
  }

  tbody {
    tr {
      @include theme.get-color('border-bottom', 'color-background', 3px solid);
      .with-logo {
        max-width: 400px;
        @include theme.get-color('background-color', 'color-background-logo-picker');
      }
    }
  }
}

.logo-selection-list {
  > div {
    width: 200px;
    height: 150px;
    display: flex;
    justify-content: center;
    @include theme.get-color('border', 'color-border', 1px solid);
    @include theme.get-color('background-color', 'color-background-logo-picker');
    cursor: pointer;

    &:hover {
      @include theme.get-color('background', 'color-background-logo-picker-hover');
    }

    img {
      max-width: 100% !important;
    }
  }
}

tr.inactive-row {
  td {
    color: #aaa !important;
  }

  .text-muted,
  .fas {
    color: #aaa !important;
  }
}

.docs-image {
  display: block;
  margin: 10px 0 20px;
  padding: 10px;
  border: 1px solid #dee2e6;
  border-radius: 4px;
}

.admin-page-content {
  .border-bottom {
    margin-bottom: 8px;
  }
}
