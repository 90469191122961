@use '../../../../../scss/vars';
@use '../../../../../scss/theme';
@use 'bordered-state';

.report-verdict {
  @extend .bordered-state;

  &.verdict-malicious {
    @include theme.get-color('color', 'color-white');
    @include theme.get-color('background-color', 'color-malicious');
    border-color: #{vars.$red-500};
  }
  &.verdict-likely_malicious {
    @include theme.get-color('background-color', 'color-likely_malicious');
    border-color: #{vars.$orange-500};
  }
  &.verdict-suspicious {
    @include theme.get-color('background-color', 'color-suspicious');
    border-color: #{vars.$yellow-500};
  }
  &.verdict-informational,
  &.verdict-no_threat {
    @include theme.get-color('color', 'color-white');
    @include theme.get-color('background-color', 'color-no_threat');
    border-color: #{vars.$green-500};
  }
  &.verdict-benign {
    @include theme.get-color('color', 'color-white');
    @include theme.get-color('background-color', 'color-benign');
    border-color: #{vars.$blue-500};
  }

  .edit-verdict-icon {
    display: none;
    position: absolute;
    top: 6px;
    right: 6px;
  }
  &:hover {
    .edit-verdict-icon {
      display: inline-block;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}

.edit-verdict-modal {
  .checkbox-wrapper {
    width: 100%;

    .checkbox-label {
      width: 100%;
    }
  }
}
