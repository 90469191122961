@use 'theme';
@use 'fonts';
@use 'ds3-elements/link';

$max-width: 384px;

.news-card {
  max-width: $max-width;

  .news-container {
    padding: 0 !important;
    border-bottom: none !important;
    height: 100%;

    &.editor-mode {
      position: relative;

      &.news-inactive {
        .static-news {
          opacity: 0.5;
        }
      }

      &:hover {
        .editor-controls {
          display: flex;
        }
      }

      .editor-controls {
        position: absolute;
        top: 8px;
        right: 8px;
        display: none;
        z-index: 1;
      }

      .static-news {
        cursor: default !important;
      }
    }

    .static-news {
      padding: 20px !important;
      text-transform: none;
      color: inherit;
      display: block;

      .news-header {
        display: flex;
        align-items: center;

        .news-header-image {
          content: url('../assets/icons/twitter-fsio.jpg');
          width: 40px;
          height: 40px;
          border-radius: 50%;
          @include theme.get-color('border', 'color-border-lightest', 1px solid);
        }

        .news-header-text {
          margin-bottom: 0;
          display: flex;
          padding-left: 16px;
          align-items: center;
          flex-wrap: wrap;
        }
      }

      .news-body {
        .news-text {
          .news-content-link {
            @extend a;
            display: inline-block;
            text-transform: none;
          }
        }
        .news-images {
          margin: 0;
          img {
            padding: 1px;
          }
        }
      }
    }
  }
}
