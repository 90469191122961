@use 'vars';
@use 'theme';

.color-100 {
  color: #{vars.$page100};
}
.color-200 {
  color: #{vars.$page200};
}
.color-300 {
  color: #{vars.$page300};
}
.color-400 {
  color: #{vars.$page400};
}
.color-500 {
  color: #{vars.$page500};
}
.color-600 {
  color: #{vars.$page600};
}
.color-700 {
  color: #{vars.$page700};
}
.color-800 {
  color: #{vars.$page800};
}
.color-900 {
  color: #{vars.$page900};
}
.color-1000 {
  color: #{vars.$page1000};
}
.color-1100 {
  color: #{vars.$page1100};
}
.color-1200 {
  color: #{vars.$page1200};
}
.color-1300 {
  color: #{vars.$page1300};
}

.color-text-main {color: #{vars.$page1100}}
.color-red {color: #{vars.$red} !important}
.color-green {color: #{vars.$green} !important}
.color-primary {color: #{vars.$blue} !important}
.color-suspicious {@include theme.get-color("color", "color-suspicious")}
.color-white {@include theme.get-color("color", "color-white")}
