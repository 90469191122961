@use '../../../../../scss/vars';

.bordered-state {
  margin: -#{vars.$gap4};
  height: calc(100% + 2 * #{vars.$gap4});
  border: 8px solid #{vars.$page200};
  background-color: #{vars.$page400};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .thumbnail-container {
    padding: 0;
    max-height: 136px;
    overflow: hidden;

    img {
      max-width: 100%;
      width: 100%;
    }
  }
}
