@use '../theme.scss';
@use '../fonts.scss';

.datepicker-container {
  position: relative;

  & > .dropdown-menu.show {
    top: 100%;
    left: 0;
    right: 0;
    min-width: 100%;
    margin-top: 4px;
  }
}

.datepicker-input {
  & > input {
    caret-color: transparent;

    &:hover {
      cursor: pointer !important;
    }
  }
}

.datepicker-option-selected {
  width: 100%;
  display: flex;
  justify-content: space-between;

  &:after {
    content: url('assets/icons/opswat/Check.svg');
    display: inline-block;
    filter: invert(26%) sepia(79%) saturate(3299%) hue-rotate(217deg) brightness(106%) contrast(98%);
    padding: 0;
  }
}

.datepicker-expanded-header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  @include theme.get-color('border-bottom', 'color-border-extra-light', 1px solid);
}

.datepicker-expanded-options-list {
  padding: 20px 0;
  min-width: 160px;

  & > a {
    gap: 8px;
  }
}

.datepicker-expanded-calendar {
  padding: 20px;
  flex-grow: 1;
  min-width: 288px;
  @include theme.get-color('border-left', 'color-border-extra-light', 1px solid);
}

.datepicker-expanded-button-group {
  padding: 16px 20px;
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  @include theme.get-color('border-top', 'color-border-extra-light', 1px solid);
}

// ---- Override react-calendar/Calendar.scss ----

.react-calendar {
  @extend %general-color;
  width: 100%;
  border: none;
  font-family: 'Roboto', sans-serif;
}

.react-calendar__month-view__weekdays,
.react-calendar__navigation__label {
  @extend .text-main, .text-m, .text-capital;

  & abbr {
    @extend .text-main, .text-m, .text-capital;
    text-decoration: none;
    cursor: default;
  }
}

.react-calendar__navigation {
  height: auto;
  gap: 8px;

  & > button {
    min-width: unset;
    padding: 0;
  }

  & > button:disabled {
    &:hover {
      cursor: default;
      pointer-events: none;
    }
  }
}

.react-calendar__navigation__label {
  pointer-events: none;
  cursor: default;
}

.react-calendar__month-view__weekdays {
  margin-bottom: 16px;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0;
  flex: 0 0 14.2857%;
}

.react-calendar__tile {
  @extend .text-main;
  padding: 8px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 14.2857%;

  & abbr {
    display: block;
    min-width: 16px;
    height: 16px;
  }

  &:enabled {
    &:hover {
      @include theme.get-color('background-color', 'color-hover-background');
    }
  }
}

.react-calendar__tile:disabled {
  @include theme.get-color('background-color', 'color-background');
  @include theme.get-color('color', 'color-primary-disabled');
  pointer-events: none;
  cursor: default;
}

.react-calendar__tile--now {
  background-color: inherit;
}

.react-calendar__month-view__days__day--neighboringMonth {
  @include theme.get-color('color', 'color-text-unavailable');
}

.react-calendar__month-view__days__day--weekend {
  color: inherit;
}

.react-calendar__tile--selectRange {
  @include theme.get-color('background-color', 'color-background-selected');
}

.react-calendar__tile--hasActive,
.react-calendar__tile--active {
  @include theme.get-color('background-color', 'color-primary', '', !important);
  @include theme.get-color('color', 'color-text-hover', '', !important);

  &:hover {
    @include theme.get-color('background-color', 'color-primary-hover', '', !important);
  }
}

// ---- ----
