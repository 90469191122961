@use 'theme';
@use 'vars';
@use 'fonts';

.appbar {
  height: vars.$top-menu-height;
  width: 100%;
  max-width: 100vw;
  padding: 0 20px;
  @include theme.get-color('background-color', 'color-background');

  @media screen and (max-width: 991px) {
    position: fixed;
    min-height: vars.$top-menu-height-mobile;
    height: auto;
    padding: 0;
    opacity: 1;
    z-index: 100;
    transition: opacity 0.3s ease;
  }

  &:not(.has-sub-navbar)::after {
    content: '';
    position: absolute;
    top: 100%;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
    opacity: 0.05;
    background: linear-gradient(180deg, black 0%, rgba(0, 0, 0, 0.5) 28%, rgba(0, 0, 0, 0) 100%);
    z-index: -1; // allow additional navigation bar to overflow this shadow
  }
}

.appbar-mobile-hidden {
  @media screen and (max-width: 991px) {
    opacity: 0;
  }
}

.appbar > a {
  @extend .text-note, .text-upper, .text-m;

  @media screen and (max-width: 991px) {
    font-size: 14px;
    text-transform: capitalize;
  }
}

.appbar-container {
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  height: 100%;
  width: 100%;

  @media screen and (max-width: 991px) {
    min-height: 60px;
  }
}

.appbar-collapse {
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 991px) {
    max-height: calc(100vh - 64px - 60px); // Exclude top and bottom bars
    overflow-y: scroll;
  }

  & a {
    text-decoration: none;
  }
}

.navbar-nav {
  @media screen and (min-width: 992px) {
    gap: 20px;
  }
}

.appbar-logo {
  height: 40px;

  @media screen and (max-width: 991px) {
    max-height: 30px;
  }
}

.appbar-icons-wrapper {
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 991px) {
    height: 60px;
  }
}

.navbar-toggler {
  border: none;
  padding: 0;
  margin-right: 20px;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.appbar-toggler-icon {
  font-size: 16px;
  @include theme.get-color('color', 'color-text');
}

.logo {
  height: 42px;
  width: auto;

  @media screen and (max-width: 991px) {
    padding-left: 20px;
  }
}

.appbar-item-wrapper {
  height: vars.$top-menu-height;
  display: flex;
  align-items: center;
  padding: inherit;
  cursor: pointer;

  @media screen and (max-width: 991px) {
    height: 50px;
    width: 100%;
    @include theme.get-color('border-top', 'color-border-extra-light', 1px solid);
    padding: 0 20px;
  }
}

.appbar-dropdown-menu-item {
  @media screen and (max-width: 991px) {
    padding: 0 20px;
    min-height: 50px;
    display: flex;
    align-items: center;
    @include theme.get-color('background-color', 'color-button-background', '', !important);
  }
}

.appbar-dropdown-toggle {
  display: flex;
  align-items: center;
}

.dropdown-toggle {
  @media screen and (max-width: 991px) {
    &::after {
      content: url('assets/icons/opswat/solid_right.svg');
      border-top: none;
    }

    &.show::after {
      content: url('assets/icons/opswat/solid_down.svg');
    }
  }
}

.appbar-item {
  @extend .text-note, .text-m, .text-upper;
  margin: auto;
  @include theme.get-color('color', 'color-text');
  padding-top: calc(vars.$top-menu-height / 2 - 0.5rem); // increase clickable area to appbar height
  padding-bottom: calc(vars.$top-menu-height / 2 - 0.5rem); // increase clickable area to appbar height

  @media screen and (max-width: 991px) {
    font-size: 14px;
    font-weight: 400 !important;
    text-transform: capitalize !important;
    height: 50px;
    display: flex;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 0px;
    width: 100%;
  }
}

.appbar-item:hover {
  text-decoration: none;
  @include theme.get-color('color', 'color-primary-hover', '', !important);
}

.appbar-item-selected {
  padding-bottom: calc(vars.$top-menu-height / 2 - 1rem);
  @include theme.get-color('border-bottom', 'color-primary', 4px solid);

  @media screen and (max-width: 991px) {
    @include theme.get-color('border-left', 'color-primary', 4px solid);
    border-bottom: none !important;
    padding: 0 20px 0 16px;
  }
}

.appbar-item-selected::after {
  @media screen and (max-width: 991px) {
    content: url('assets/icons/opswat/Check.svg');
    display: inline-block;
    filter: invert(26%) sepia(79%) saturate(3299%) hue-rotate(217deg) brightness(106%) contrast(98%);
    padding: 0;
  }
}

.appbar-divider {
  width: 1px;
  height: 50%;
  @include theme.get-color('background-color', 'color-border-extra-light');

  @media screen and (max-width: 991px) {
    width: 100%;
    height: 19px; //+1px border will be added
  }
}

.mobile-menu-opened-overlay,
.appbar-expanded-overlay {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(27, 39, 60, 0.8);
  z-index: 99;
  display: block;
}

.no-scroll {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

.appbar-menu-close-mobile {
  display: none;

  @media screen and (max-width: 991px) {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    width: 100vw;
    padding: 16px 20px;
    z-index: 100;
    @include theme.get-color('background-color', 'color-background');
    box-shadow: 0 10px 30px 0 black;
  }
}

.appbar-menu-close-button {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  height: 32px;
  width: 100%;
  border: none;
  @include theme.get-color('color', 'color-white');
  @include theme.get-color('background-color', 'color-primary');
}

.appbar-dropdown-wrapper {
  min-height: vars.$top-menu-height;
  height: auto;
  display: flex;
  align-items: center;
  padding: inherit;
  cursor: pointer;

  @media screen and (max-width: 991px) {
    min-height: 50px;
    width: 100%;
    display: block;
    @include theme.get-color('border-top', 'color-border-extra-light', 1px solid);
    // Label
    & > a {
      font-weight: 400;
      font-size: 14px;
      text-transform: capitalize;
      @include theme.get-color('color', 'color-text');
      @include theme.get-color('background-color', 'color-background');
      min-height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;
    }

    // Dropdown
    & > .dropdown-menu.show {
      margin: 0;
      padding: 0;
      @include theme.get-color('background-color', 'color-background');
      border: none;

      & > a {
        font-weight: 400;
        font-size: 14px;
        text-transform: capitalize;
        @include theme.get-color('color', 'color-text');
        @include theme.get-color('background-color', 'color-background', '', !important);
        padding: 0 20px 0 30px;
      }
    }
  }
}

.appbar-dropdown-selected {
  padding-bottom: calc(vars.$top-menu-height / 2 - 1rem);
  @include theme.get-color('border-bottom', 'color-primary', 4px solid);

  @media screen and (max-width: 991px) {
    border-bottom: none !important;
    padding: 0;

    & > a {
      padding-left: 16px;
      display: flex;
      @include theme.get-color('border-left', 'color-primary', 4px solid);
    }
  }
}

.appbar-dropdown-item-selected {
  @extend .text-m;

  @media screen and (max-width: 991px) {
    @include theme.get-color('border-left', 'color-primary', 4px solid);
    font-weight: normal;
    border-bottom: none !important;
    padding: 0 20px 0 26px !important;
  }
}

.appbar-dropdown-item-selected::after {
  @media screen and (max-width: 991px) {
    content: url('assets/icons/opswat/Check.svg');
    display: inline-block;
    filter: invert(26%) sepia(79%) saturate(3299%) hue-rotate(217deg) brightness(106%) contrast(98%);
    padding: 0;
    margin-left: auto;
  }
}

.appbar-user-menu .dropdown-menu.show {
  margin-top: 4px; //4px from navbar bottom
}

.appbar-user-dropdown {
  @media screen and (min-width: 992px) {
    width: 250px;
  }
}

.appbar-user-dropdown-header {
  display: block;
  padding: 12px 16px;
}

.appbar-user-dropdown-body {
  @media screen and (min-width: 992px) {
    padding: 8px 0px;
  }
}
