@use 'color-palette';

@mixin get-color($key, $color, $prefix: '', $postfix: '') {
  @each $theme-name, $theme-color in color-palette.$themes {
    $theme: map-get(color-palette.$themes, $theme-name);
    $value: if(map-has-key($theme, $color), map-get($theme, $color), $color);
    .theme-#{$theme-name} & {
      @if $prefix == '' {
        @if $postfix == '' {
          #{$key}: $value;
        } @else {
          #{$key}: $value $postfix;
        }
      } @else {
        @if $postfix == '' {
          #{$key}: $prefix $value;
        } @else {
          #{$key}: $prefix $value $postfix;
        }
      }
    }
  }
}

%general-color {
  @include get-color('background', 'color-background');
  @include get-color('color', 'color-text');
}

%general-active {
  &:hover {
    @include get-color('background', 'color-hover-background');
  }

  &:active {
    @include get-color('background', 'color-light');
  }
}
