@use '../theme.scss';
@use '../icons.scss';

.pager-container {
  display: flex;
  justify-content: space-between;

  .page-size-select {
    .dropdown-container {
      width: 64px;

      .dropdown-menu.show {
        transform: translate(-1px, 32px) !important;
        min-width: 104px;
      }
    }
  }

  .page-size-select {
    .dropdown-container {
      .input-field-default {
        height: 32px;
      }

      .input-field {
        min-width: 0;
        min-height: 32px;

        input {
          min-width: 0;
          min-height: 32px;
          height: 32px;
        }
      }
    }
  }

  .page-select {
    @include theme.get-color('border', 'color-border', 1px solid);
    display: flex;
    align-items: stretch;

    .all-pages {
      @include theme.get-color('border-right', 'color-border', 1px solid);
      display: flex;
      align-items: center;
    }

    > a {
      display: inline-block;
      width: 32px;
      height: 32px;
      @include theme.get-color('border-right', 'color-border', 1px solid);
      @extend .ds3-icon;

      &:hover {
        @include theme.get-color('background-color', 'color-hover-background-lighter');
      }

      &:before {
        right: -7px;
        top: 6px;
        @include theme.get-color('color', 'color-text');
      }
      &.disabled {
        &:before {
          @include theme.get-color('color', 'color-primary-disabled');
        }
      }

      &.page-first {
        @extend .ds3-chevron-left-double;
      }
      &.page-prev {
        @extend .ds3-chevron-left;
      }
      &.page-next {
        @extend .ds3-chevron-right;

        &:before {
          right: -9px;
        }
      }
      &.page-last {
        @extend .ds3-chevron-right-double;
        border-right: none;

        &:before {
          right: -10px;
        }
      }
    }

    .dropdown-container {
      width: auto;
      height: 100%;

      .dropdown-menu.show {
        transform: translate(-1px, 32px) !important;
        max-height: 143px;
        min-width: 128px;

        .dropdown-item.active {
          @extend .ds3-icon;
          @extend .ds3-check;
          position: relative;

          &:before {
            @include theme.get-color('color', 'color-primary');
            position: absolute;
            right: 16px;
            top: 5px;
          }
        }
      }
    }
  }
}
