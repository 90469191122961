.yara-report {
  & .yara-item {
    & .item-header {
      display: flex;
      align-items: center;
      width: 100%;

      & .search {
        opacity: 0;
        margin: 0 8px;
      }

      &:hover .search {
        opacity: 1;
      }
    }
  }
}
