@use 'theme';

.feature-note {
  @include theme.get-color('background-color', 'color-note-background');

  position: fixed;
  top: 73px;
  right: 12px;

  & .title {
    font-size: 1rem;
    font-weight: 600;
    @include theme.get-color('background-color', 'color-note-background');
    color: white;
  }

  & .body {
    font-size: 0.875rem;
    font-weight: 400;
    color: white;

    & a {
      background-color: #f0f0f0;
      color: #212529;
    }
  }
}
