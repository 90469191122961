@use '../../../../../scss/theme';
@use '../../../../../scss/vars';
@use '../../../../../scss/ds3-elements/spacing';

.report-content-wrapper {
  .report-filter-wrapper {
    height: 100%;
    right: -20px;
    width: 6px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: -42px;
      height: 100%;
      width: 40px;
      opacity: 0.05;
      background: linear-gradient(270deg, #000 0%, rgba(0, 0, 0, 0.5) 28.13%, rgba(0, 0, 0, 0) 100%);
      z-index: -1;
    }

    .toggle-button {
      cursor: pointer;
      position: absolute;
      margin-left: -16px;
      top: 32px;
      padding: 8px 0;
      @include theme.get-color('background-color', 'color-white');
      box-shadow: 0px 2px 8px 0px rgba(10, 36, 86, 0.15);
      z-index: 1;

      &:hover {
        @include theme.get-color('color', 'color-primary');
      }
    }

    .report-filter {
      position: relative;
      z-index: 2;
      @include theme.get-color('background-color', 'color-white');
      padding: 0;

      .filter-section:not(:last-child) {
        margin-bottom: 16px;
      }

      > div {
        padding: 40px 20px;
      }

      .filter-buttons {
        display: flex;
        gap: 24px;
        justify-content: flex-end;
      }

      .checkbox-component-container {
        width: 100%;
        justify-content: space-between;

        .checkbox-label {
          @extend .ellipsis;
          width: 115px;
        }
      }

      .flags-filter-short {
        height: 188px;
        overflow-y: hidden;
      }
    }
  }

  .report-content-filter-col {
    position: relative;

    &.filter-active {
      width: 320px;

      .report-filter-wrapper {
        width: 100%;
      }
    }

    &:not(.filter-active) {
      .report-filter {
        width: 7px;
        height: 100%;
        margin-left: -1px;

        .filter-content,
        .filter-buttons {
          display: none;
        }
      }

      @media (max-width: 1279px) {
        .report-filter-wrapper {
          width: 0px;

          .report-filter {
            width: 0px;
            margin-left: 0px;
          }
        }
      }
    }
  }
}

.filter-button-container {
  display: flex;
  align-items: flex-start;
  padding-left: 24px;
  margin-left: 24px;
  height: 24px;
  @include theme.get-color('border-left', 'color-border', 1px solid);

  .ds3-filter {
    :hover {
      color: #{vars.$blue200};
    }
  }

  &.filter-active {
    .ds3-filter {
      @include theme.get-color('color', 'color-primary');
    }
  }

  .filter-applied-mark {
    position: absolute;
    width: 12px;
    height: 12px;
    top: 0px;
    right: -6px;
    border-radius: 50%;
    @include theme.get-color('background-color', 'color-primary');
    @include theme.get-color('border', 'color-white', 2px solid);
  }
}
