.verdict-small-screen {
  display: none;
}

.advertisement-small-screen {
  display: none;
}

@media (max-width: 1500px) {
  .verdict-column {
    display: none;
  }

  .verdict-small-screen {
    display: inline-block;

    .card-title,
    .verdict-confidence,
    .verdict-note {
      display: none;
    }
  }

  .advertisement-small-screen {
    display: unset;
    border-radius: 0 !important;
  }

  .advertisement-header-small-screen {
    max-height: 2.5rem;
    padding: 0px 16px;
  }

  .advertisement-content-small-screen {
    margin: 8px 16px;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .report-content-column {
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
}

.report-block-group {
  display: flex;

  .report-block {
    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
    }
  }
}

@media (max-width: 550px) {
  .report-block-group {
    .report-block:first-child {
      display: none;
    }

    .report-block:last-child {
      border-radius: 4px;
      flex-grow: 1;

      .final-verdict {
        border-radius: 4px;
      }
    }
  }
}

.menu-item-title-short {
  display: none;
}

@media (max-width: 1199px) {
  .report-page-component .report-content {
    padding: 0;
  }

  .report-menu {
    .list-group {
      flex-wrap: wrap;

      .list-group-item {
        border: none;
        width: auto;
        color: #2f93ff;
        padding: 5px 10px;

        &:hover {
          background: transparent;
          color: #1b67bb;
        }

        .menu-item-title-long {
          display: none;
        }
        .menu-item-title-short {
          display: inline;
        }
      }
    }
  }

  .advertisement-small-screen {
    display: none;
  }
}

@media (max-width: 1500px) {
  .report-block-group {
    .card-body {
      padding: 0;
    }

    .report-block {
      &:first-child {
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
      }

      &:last-child {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
      }

      .report-navpath .navpath-item {
        padding: 0.75rem;
        padding-right: 0;
        border-right: none;

        &:first-child {
          padding-left: 20px;
        }

        &:not(:first-child) {
          padding-left: 5px;
        }

        &:not(:last-child) {
          padding-right: 5px;

          &:after {
            content: '-';
            margin-left: 11px;
          }
        }
      }

      .final-verdict {
        padding: 10.5px 15px;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;

        .spinner-border {
          width: 20px;
          height: 20px;
          vertical-align: middle;
          margin-left: 5px;
        }
      }
    }
  }
}

.small-screen-column {
  @media (max-width: 1500px) {
    flex-direction: column;
  }
}

.large-screen-nowrap {
  @media (min-width: 1501px) {
    white-space: nowrap;
  }
}
