@mixin thin-animation($thickness, $size, $start, $dash, $dash-end, $direction) {
  $end: 180deg;

  @if $direction == cw {
    $end: calc($start - 180deg);
  } @else {
    $end: calc($start + 180deg);
  }

  @keyframes #{$thickness}-#{$size} {
    0% {
      stroke-dashoffset: $dash;
      transform: rotate($start);
    }

    37.5% {
      stroke-dashoffset: $dash-end;
      transform: rotate($start);
    }

    62.5% {
      stroke-dashoffset: $dash-end;
      transform: rotate($start);
    }

    100% {
      stroke-dashoffset: $dash;
      transform: rotate($end);
    }
  }
}

@mixin medium-animation($thickness, $size, $start, $dash, $dash-end, $direction) {
  $end: 0deg;

  @if $direction == cw {
    $end: calc($start - 180deg);
  } @else {
    $end: calc($start + 180deg);
  }

  @keyframes #{$thickness}-#{$size} {
    0% {
      stroke-dashoffset: $dash;
      transform: rotate($start);
    }

    6.25% {
      stroke-dashoffset: $dash;
      transform: rotate($start);
    }

    43.75% {
      stroke-dashoffset: $dash-end;
      transform: rotate($start);
    }

    56.25% {
      stroke-dashoffset: $dash-end;
      transform: rotate($start);
    }

    93.75% {
      stroke-dashoffset: $dash;
      transform: rotate($end);
    }

    100% {
      stroke-dashoffset: $dash;
      transform: rotate($end);
    }
  }
}

@mixin thick-animation($thickness, $size, $start, $dash, $dash-end, $direction) {
  $end: 0deg;

  @if $direction == cw {
    $end: calc($start - 180deg);
  } @else {
    $end: calc($start + 180deg);
  }

  @keyframes #{$thickness}-#{$size} {
    0% {
      stroke-dashoffset: $dash;
      transform: rotate($start);
    }

    12.5% {
      stroke-dashoffset: $dash;
      transform: rotate($start);
    }

    50% {
      stroke-dashoffset: $dash-end;
      transform: rotate($start);
    }

    87.5% {
      stroke-dashoffset: $dash;
      transform: rotate($end);
    }

    100% {
      stroke-dashoffset: $dash;
      transform: rotate($end);
    }
  }
}

@mixin circle($dash, $name, $animation-time) {
  $animation-fill-style: ease-in-out;

  &.base-form {
    fill: none;
    transform-origin: center;
    stroke-dasharray: $dash;
  }

  &.thin {
    stroke: #1d6bfc;
    stroke-width: 1px;
    animation: thin-#{$name} $animation-time infinite $animation-fill-style;
  }

  &.thin-rev {
    stroke: #1d6bfc;
    stroke-width: 1px;
    animation: thin-rev-#{$name} $animation-time infinite $animation-fill-style;
  }

  &.medium {
    stroke: #1d6bfc;
    stroke-width: 2px;
    animation: medium-#{$name} $animation-time infinite $animation-fill-style;
  }

  &.medium-rev {
    stroke: #1d6bfc;
    stroke-width: 2px;
    animation: medium-rev-#{$name} $animation-time infinite $animation-fill-style;
  }

  &.thick {
    stroke: #1d6bfc;
    stroke-width: 4px;
    animation: thick-#{$name} $animation-time infinite $animation-fill-style;
  }

  &.thick-rev {
    stroke: #1d6bfc;
    stroke-width: 4px;
    animation: thick-rev-#{$name} $animation-time infinite $animation-fill-style;
  }
}

$outer-dash: var(--outer-dash);
$middle-dash: var(--middle-dash);
$inner-dash: var(--inner-dash);
$pi: 3.1416;

.outer-circle {
  $dash: calc((2 * $pi * 60));
  $start-position: 270deg;

  @include circle($dash, outer, 2200ms);
  @include thin-animation(thin, outer, $start-position, $dash, calc($dash / 2), ccw);
  @include medium-animation(medium, outer, $start-position, $dash, calc($dash / 2), ccw);
  @include thick-animation(thick, outer, $start-position, $dash, calc($dash / 2), ccw);
  @include thin-animation(thin-rev, outer, $start-position, $dash, calc($dash * 1.5), cw);
  @include medium-animation(medium-rev, outer, $start-position, $dash, calc($dash * 1.5), cw);
  @include thick-animation(thick-rev, outer, $start-position, $dash, calc($dash * 1.5), cw);
}

.middle-circle {
  $dash: calc((2 * $pi * 50));
  $start-position: 50deg;

  @include circle($dash, middle, 3000ms);
  @include thin-animation(thin, middle, $start-position, $dash, calc($dash / 2), ccw);
  @include medium-animation(medium, middle, $start-position, $dash, calc($dash / 2), ccw);
  @include thick-animation(thick, middle, $start-position, $dash, calc($dash / 2), ccw);
  @include thin-animation(thin-rev, middle, $start-position, $dash, calc($dash * 1.5), cw);
  @include medium-animation(medium-rev, middle, $start-position, $dash, calc($dash * 1.5), cw);
  @include thick-animation(thick-rev, middle, $start-position, $dash, calc($dash * 1.5), cw);
}

.inner-circle {
  $dash: calc(2 * $pi * 40);
  $start-position: 180deg;

  @include circle($dash, inner, 2500ms);
  @include thin-animation(thin, inner, $start-position, $dash, calc($dash / 2), ccw);
  @include medium-animation(medium, inner, $start-position, $dash, calc($dash / 2), ccw);
  @include thick-animation(thick, inner, $start-position, $dash, calc($dash / 2), ccw);
  @include thin-animation(thin-rev, inner, $start-position, $dash, calc($dash * 1.5), cw);
  @include medium-animation(medium-rev, inner, $start-position, $dash, calc($dash * 1.5), cw);
  @include thick-animation(thick-rev, inner, $start-position, $dash, calc($dash * 1.5), cw);
}

.report-scan-spinner {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;

  .spinner-container {
    display: flex;
    justify-content: center;
    position: relative;

    .spinner-central-icon {
      position: absolute;
      top: 60px;
      width: 52px;
    }
  }
}
