@use '../theme';
@use '../vars';
@use '../fonts';

.progress-bubble {
  cursor: pointer;
  text-align: center;
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 9999px;
  border: 1.5px #{vars.$page700} solid;
  z-index: 1;
  color: #{vars.$page700};

  > span {
    @extend .text-main;
    z-index: 2;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 1px;
  }
}

.progress-bubble.active {
  border: 1.5px #{vars.$blue} solid;
  color: #{vars.$blue};
}

.progress-bubble.finished {
  @include theme.get-color('color', 'color-background');
  border: 1.5px #{vars.$blue} solid;
  background-color: #{vars.$blue};

  &:hover {
    border: 1.5px #{vars.$blue200} solid;
    background-color: #{vars.$blue200};
  }

  > span {
    left: 3px;
    position: absolute;
  }
}

.progress-bubble-label {
  @extend .text-note;
  color: #{vars.$blue};
}

.progress-dash {
  width: 16px;
  height: 1.5px;
  background-color: #{vars.$page700};
}

.progress-dash.finished {
  background-color: #{vars.$blue};
}
