@use '../theme';
@use '../vars';

.card {
  @include vars.standard-shadow;

  &.card-hover {
    @include vars.standard-shadow-hover;
  }

  .card-body {
    padding: 0;

    > div:not(.body-items-container) {
      padding: #{vars.$gap4};
      @include theme.get-color('border-bottom', 'color-border-lightest', 1px solid);
    }

    .body-items-container {
      padding: 0 !important;
      display: flex;
      flex-grow: 1;
      flex-direction: row;

      > div {
        padding: #{vars.$gap4};
      }
    }

    > .card-actions {
      padding: 0 8px !important;
      display: flex;
      align-items: center;
    }
  }

  &.single-card {
    .card-body {
      > div:not(.body-items-container) {
        padding: 32px 24px;
        border-bottom: none;
      }
      .tile {
        padding: #{vars.$gap8} #{vars.$gap6};
        margin: #{vars.$gap8} -#{vars.$gap6} -#{vars.$gap8};
      }
    }
  }
}

.accordion {
  .card {
    @include vars.standard-shadow;

    .card-body {
      padding: 0;

      > div:not(.body-items-container) {
        padding: #{vars.$gap8} #{vars.$gap4};
      }

      .body-items-container {
        > div {
          padding: #{vars.$gap8} #{vars.$gap4};
        }
      }
    }
  }
}

.accordion {
  margin-bottom: 8px;

  .card {
    @include vars.standard-shadow;
    @include vars.standard-shadow-hover;

    .card-header {
      height: 50px;
      display: flex;
      justify-content: space-between;
      border-width: 0;
      @include theme.get-color('background-color', 'color-background');
      @include theme.get-color('border-bottom', 'color-border-lightest', 1px solid);

      &.collapse-error-header {
        background: #{vars.$red-600};
      }

      &.collapse-benign-header {
        background: #{vars.$blue-600};
      }

      .accordion-button {
        padding: 0px;

        .accordion-header-content {
          padding: 0px;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: stretch;
          justify-content: flex-end;
          align-content: stretch;
          user-select: text;

          > div {
            padding: 0px 8px;
            display: flex;
            align-items: center;

            &:first-child {
              padding-left: 16px !important;
              flex-grow: 1;
            }

            &:nth-child(n + 1) {
              width: 15%;
            }

            &.tags-preview {
              justify-content: flex-end;
              width: 20%;

              @media (max-width: 1679px) {
                width: 25%;
              }
              @media (max-width: 1100px) {
                width: 30%;
              }
              @media (max-width: 900px) {
                display: none !important;
              }
            }

            &.mitre-preview {
              width: 20%;

              @media (max-width: 1650px) {
                width: 25%;
              }
            }

            &.osint-verdict {
              width: 11%;

              @media (max-width: 1679px) {
                width: 15%;
              }
              @media (max-width: 1150px) {
                width: 11%;
              }
              @media (max-width: 930px) {
                width: 15%;
              }
              @media (max-width: 640px) {
                width: 20%;
              }
            }

            &.card-actions {
              width: 33px;

              .icon-button.icon-copy {
                margin: 0;
                padding: 16px 8px;
                margin-left: -8px;
              }
            }

            &.section-short {
              width: 80px;
              flex-grow: 0;
              max-width: 80px;
            }

            &.location {
              width: 158px;
            }

            &.number-badge {
              width: 100px;
            }

            &:nth-child(n + 2) {
              @include theme.get-color('border-left', 'color-border-lightest', 1px solid);
            }

            &.col-mob-3 {
              @media (max-width: 991px) {
                width: 25%;
              }
            }

            .text-label-upper {
              color: #{vars.$page800};
            }
          }
        }

        .verdict-marker {
          height: 100%;
          width: 16px;
          border-left-width: 4px;
          border-left-style: solid;

          // Verdict colored sripe on card
          &.verdict-malicious {
            @include theme.get-color('border-left-color', 'color-malicious');
          }

          &.verdict-likely_malicious {
            @include theme.get-color('border-left-color', 'color-likely_malicious');
          }

          &.verdict-suspicious {
            @include theme.get-color('border-left-color', 'color-suspicious');
          }

          &.verdict-informational,
          &.verdict-no_threat {
            @include theme.get-color('border-left-color', 'color-no_threat');
          }

          &.verdict-benign {
            @include theme.get-color('border-left-color', 'color-benign');
          }

          &.verdict-unknown {
            @include theme.get-color('border-left-color', 'color-unknown');
          }
        }
      }

      .title {
        text-transform: capitalize;
      }

      .indicators {
        display: flex;
        align-items: center;
        margin-left: 8px;

        .badge:not(:last-child) {
          margin-right: 16px;
        }
      }

      &:not(.with-arrow) {
        .accordion-button {
          font-size: #{vars.$secondary-content-font-size};
          font-weight: 500;
          line-height: 20px;

          &:after {
            display: none;
          }
        }
      }
    }

    .card-body {
      padding: 0;
      font-size: #{vars.$secondary-content-font-size};

      .row {
        .col-supplement {
          @include theme.get-color('background-color', 'color-hover-background');
          @include theme.get-color('border-left', 'color-border', 1px solid);
          margin-top: -19px;
          padding-top: 19px;
          margin-bottom: -19px;

          .data-block {
            .data-row {
              .label {
                @include theme.get-color('color', 'color-text-muted');
              }
            }
          }
        }
      }
    }
  }
}

.card.verdict-card {
  .card-body {
    > div:first-child {
      @include theme.get-color('border-right', 'color-border-lightest', 1px solid);
    }
  }

  @media (max-width: 991px) {
    &.upload-file-item {
      margin-bottom: 16px !important;

      .card-body {
        flex-direction: column;

        .body-items-container {
          flex-direction: column;

          > div {
            width: 100%;
          }
          > div:first-child {
            width: 100%;

            .report-verdict {
              padding: 16px 8px 8px;
            }
          }
          .progress-block {
            @include theme.get-color('border-bottom', 'color-border-lightest', 1px solid);
          }
        }

        .card-actions {
          height: 35px;
          justify-content: flex-end;
          display: flex;
          margin-top: -35px;
        }
      }
    }
  }

  @media (max-width: 650px) {
    &:not(.upload-file-item) {
      .card-body {
        flex-direction: column;

        > div:first-child {
          width: 100%;

          .report-verdict {
            padding: 16px 8px 8px;
          }
        }

        .progress-block {
          width: 100%;
          @include theme.get-color('border-bottom', 'color-border-lightest', 1px solid);
          border-right: none;
        }
      }
    }
  }
}

.card-header {
  .more-techniques-icon {
    position: absolute;
    right: 4px;
    bottom: 0px;
    font-size: 14px;
  }
}

.card.hoverable {
  @include vars.standard-shadow-hover;
}
