.vote-bar {
  background-image: linear-gradient(to right, #dc3545, #28a745);
  border-radius: 10px;
  width: 100%;
  height: 19px;
  padding-left: 10px;
  padding-right: 10px;
}

.vote-dot {
  background-color: white;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  margin: 2px;
  box-shadow: 5px 5px 8px -5px #000000;
  transform: translate(-50%, 0);
}

.community-votes {
  display: flex;
  margin-bottom: 10px;
}

.community-votes .btn-danger {
  border: 1px solid #dc3545;
  color: #dc3545;
  background-color: transparent;
}

.community-votes .btn-success {
  border: 1px solid #28a745;
  color: #28a745;
  background-color: transparent;
}

.community-votes button {
  background-color: transparent !important;
  margin: 5px;
  flex: 1;
  font-size: 0.8rem;
}

.community-votes .self-voted-benign {
  background-color: #28a745 !important;
  color: white;
}

.community-votes .self-voted-malicious {
  background-color: #dc3545 !important;
  color: white;
}

.vote-alert {
  max-width: 295px;
  margin: 0.25em;
}

@media (min-width: 1499px) {
  .hide-community-small-screen {
    display: none;
  }
}
