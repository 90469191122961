@use '../theme.scss';

.range_wrapper {
  display: flex;
  gap: 10px;
  align-items: flex-end;
}

.range_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 32px;
  flex-grow: 1;
}

.sliders_control {
  position: relative;
}

.form_control {
  position: relative;
  top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @include theme.get-color('color', 'color-text');
  margin-bottom: 12px;
}

.form_control_container {
  height: 12px;
  & > span {
    position: relative;
    top: -6px;
  }
}

.report-filter {
  .form_control {
    .form_control_container {
      & > span {
        top: 0;
      }
    }
  }
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  pointer-events: all;
  width: 16px;
  height: 16px;
  background-color: transparent;
  background-image: url('assets/icons/opswat/Ellipse.svg');
  outline: 0;
  border: none;
  z-index: 3;
  cursor: pointer;
}

input[type='range']::-moz-range-thumb {
  appearance: none;
  pointer-events: all;
  width: 16px;
  height: 16px;
  background-color: transparent;
  background-image: url('assets/icons/opswat/Ellipse.svg');
  outline: 0;
  border: none;
  box-shadow: none;
  z-index: 3;
  cursor: pointer;
}

input[type='range']::-ms-thumb {
  appearance: none;
  pointer-events: all;
  width: 16px;
  height: 16px;
  background-color: transparent;
  background-image: url('assets/icons/opswat/Ellipse.svg');
  outline: 0;
  border: none;
  z-index: 3;
  cursor: pointer;
}

input[type='range']:not(.form-control) {
  -webkit-appearance: none;
  appearance: none;
  height: 6px;
  border-radius: 4px;
  width: 100%;
  position: absolute;
  background-color: #e9eaeb;
  pointer-events: none;
}

#fromSlider {
  height: 0;
  z-index: 2;
  margin-top: 3px;
}

.sliders_control > .disabled::-webkit-slider-thumb {
  //TODO replace filter if disabled version created
  filter: invert(71%) sepia(9%) saturate(174%) hue-rotate(177deg) brightness(94%) contrast(90%);
  cursor: default;
  pointer-events: none;
}
