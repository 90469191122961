@font-face {
  font-family: 'icomoon';
  src: url('/assets/fonts/icomoon.eot');
  src:
    url('/assets/fonts/icomoon.ttf') format('truetype'),
    url('/assets/fonts/icomoon.woff') format('woff'),
    url('/assets/fonts/icomoon.svg#icomoon') format('svg'),
    url('/assets/fonts/icomoon.eot') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@mixin icon-font {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 20px;
  font-size: 16px;
}

h1,
.h1 {
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}

h2,
.h2 {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

h3,
.h3 {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  @media screen and (max-width: 991px) {
    font-size: 16px;
  }
}

h4,
.h4 {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 20px;
}

h5,
.h5 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.small-header {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 4px;
}

.text-main {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.text-m {
  font-weight: 500 !important;
}

.text-i {
  font-style: italic !important;
}

.text-u {
  text-decoration: underline !important;
}

.text-upper {
  text-transform: uppercase !important;
}

.text-lower {
  text-transform: lowercase !important;
}

.text-capital {
  text-transform: capitalize !important;
}

.text-note {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.text-note-medium {
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.text-label {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.text-label-bold {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.text-label-upper {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
}

.text-caption {
  font-size: 11px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
}

.text-footnote {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.text-muted {
  color: #485161 !important; // color-text-muted
}

.text-muted-2 {
  color: #707682;
}

.text-normal {
  color: #1b273c; // color-text
}

.font-size-12 {
  font-size: 12px !important;
}
.font-size-13 {
  font-size: 13px !important;
}
.font-size-14 {
  font-size: 14px !important;
}
.font-size-15 {
  font-size: 15px !important;
}
.font-size-16 {
  font-size: 16px !important;
}

.line-height-150 {
  line-height: 150%;
}

.line-height-main {
  line-height: 20px;
}
