.report-result-item {
  border: 1px solid rgb(213, 213, 213);
  display: flex;
  cursor: pointer;

  &:hover {
    background: rgb(250, 250, 250);
  }

  &:first-of-type {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-of-type {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &:nth-of-type(n + 2) {
    border-top: none;
  }

  .search-item-section {
    display: flex;
    padding: 15px 10px;
    box-sizing: border-box;
    flex-grow: 1;

    &.left-column {
      align-items: flex-start;
    }

    &.name-section {
      width: 14%;
    }
    &.type-section {
      width: 28%;
    }
    &.matches-section {
      width: 15%;
      position: relative;
      white-space: nowrap;

      .matches-overview {
        max-width: 90%;

        > * {
          max-width: 100%;
        }
      }
    }
    &.date-section {
      width: 10%;
      font-size: 15px;
    }
    &.verdict-section {
      width: 11%;
      align-items: center;
    }
    &.link-section {
      width: 7%;
    }
    .small-screen-rebase {
      display: none;
    }

    @media (max-width: 1460px) {
      &.type-section {
        width: 38%;
      }
      &.matches-section {
        width: 17%;
      }
      &.date-section {
        width: 15%;
      }
      &.verdict-section {
        width: 10%;
      }
      &.link-section {
        display: none;
      }
    }

    @media (max-width: 1120px) {
      &.type-section {
        width: 45%;
      }
      &.matches-section {
        width: 23%;

        .matches-overview {
          font-size: 13px;
        }
      }
      &.date-section {
        width: 10%;
      }
      &.verdict-section {
        width: 14%;

        .badge {
          font-size: 13px !important;
          padding: 4px 8px;
        }
      }
      .time-part {
        display: none;
      }
    }

    @media (max-width: 1000px) {
      &.name-section {
        width: 14%;

        .small-screen-rebase.date-rebase {
          display: block;
        }
      }
      &.type-section {
        width: 50%;
      }
      &.matches-section {
        width: 21%;
      }
      &.date-section {
        display: none;
      }
      &.verdict-section {
        width: 15%;
      }
    }

    @media (max-width: 900px) {
      &.name-section {
        width: 18%;
      }
      &.type-section {
        width: 63%;

        .small-screen-rebase.matches-rebase {
          display: block;

          .matches-overview {
            font-size: 13px;
            line-height: 19px;
            white-space: nowrap;
          }
        }
      }
      &.matches-section {
        display: none;
      }
      &.verdict-section {
        width: 19%;
        align-items: flex-start;
      }
    }

    @media (max-width: 790px) {
      &.type-section {
        width: 67%;
      }
      &.verdict-section {
        width: 15%;
      }
    }

    @media (max-width: 720px) {
      &.name-section {
        width: 25%;
      }
      &.type-section {
        display: none;
      }
      &.matches-section {
        width: 40%;
        display: block;
      }
      &.verdict-section {
        width: 25%;
        align-items: center;
      }
    }

    @media (max-width: 500px) {
      &.name-section {
        width: 35%;

        .small-screen-rebase.date-rebase {
          display: none;
        }
      }
      &.matches-section {
        display: none;
      }
      &.date-section {
        width: 30%;
        display: block;
      }
      &.verdict-section {
        width: 35%;
        align-items: flex-start;
      }
    }

    .report-item-verdict {
      font-size: 15px;
      padding: 6px 15px;
    }
  }
}

.page-item.disabled {
  display: none;
}

.reports-search-form {
  .form-label {
    text-align: right;
  }

  @media (max-width: 575px) {
    .form-label {
      text-align: left;
    }
  }
}

.matches-overview {
  font-size: 14px;

  > * {
    display: block;
  }

  .match-files-count {
    color: purple;
  }

  .popover-container {
    .matches-details-link {
      padding: 5px;
      font-size: 16px;
    }

    .matches-details-list {
      list-style: none;
      padding-left: 1px;

      > li {
        margin-bottom: 10px;

        &:before {
          content: '-';
          margin-right: 4px;
        }
      }

      .sublist {
        list-style: none;
        padding-left: 20px;
      }
    }

    .popover {
      left: 32px;
      min-width: 200px;
    }

    @media (min-width: 1500px) {
      .popover {
        max-width: 450px;
        width: 450px;
      }
    }
  }
}

.reports-data-table-top-data {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin: 8px 0;

  & .select-control {
    width: 80px;
  }

  & .searchControl {
    margin-right: 16px;
    min-width: 320px;
  }

  @media screen and (max-width: 670px) {
    flex-direction: column-reverse;
    align-items: inherit;
  }
}

.td-ellipsis {
  position: relative;

  &:before {
    content: '&nbsp;';
    visibility: hidden;
  }
}

.with-ellipsis {
  left: 15px;
  right: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 320px;

  @media (min-width: 600px) {
    width: 400px;
  }

  @media (min-width: 820px) {
    width: 480px;
  }
}

.reports-data-table-overview {
  .tags {
    gap: 7px;
  }

  .flame {
    color: rgb(180, 0, 0);
  }

  td {
    padding: 10px 15px;
  }

  & .with-ellipsis {
    @media (max-width: 820px) {
      width: 480px;
    }

    @media (max-width: 768px) {
      width: 400px;
    }

    @media (max-width: 600px) {
      width: 100%;
    }
  }
}

.search-reputation-summary {
  width: 567px;

  @media (max-width: 600px) {
    width: 100%;
  }

  &.sha256-width {
    width: 690px;

    @media (max-width: 700px) {
      width: 100%;
    }
  }
}

.search-checkbox {
  @media (max-width: 575px) {
    margin-left: 16px;
  }
}

.search-button {
  margin-right: 24px;

  @media (max-width: 1355px) {
    margin-right: 8px;
  }
  @media (max-width: 991px) {
    margin-right: 24px;
  }
  @media (max-width: 727px) {
    margin-right: 8px;
  }
  @media (max-width: 575px) {
    margin-right: 0;
  }
}

.search-match-popover {
  top: -60px;

  .popover-arrow {
    position: relative;
    top: 44px;
    left: -27px;
  }
}
