@use 'theme';
@use 'icons';
@use 'threat-hunting';
@use 'fonts';

.home-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  @media (max-width: 991px) {
    margin-top: 70px;
    position: relative;

    > .home-container-background {
      margin-top: -54px !important;
    }

    .mobile-tabbed-container {
      width: 100vw !important;
      margin-left: -20px !important;
      margin-top: 14px;
      margin-bottom: 16px;
    }
  }

  > .home-container-background {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 538px;
    margin-left: -20px;
    margin-right: -20px;
    margin-top: -40px;
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: 991px) {
      margin-left: -20px;
      margin-right: -20px;
      margin-top: -53px;
      width: 100vw;
      height: 160px;
    }
  }

  &.basic-owl {
    .home-container-background {
      background-image: url('assets/backgrounds/home-basic.jpg');
    }
    .owl-background {
      background-image: url('assets/backgrounds/basic-owl-blue.png');

      @media (max-width: 991px) {
        background-image: url('assets/backgrounds/basic-owl-blue-mobile.png');
      }
    }
  }
  &.winter-owl {
    .home-container-background {
      background-image: url('assets/backgrounds/home-winter.jpg');
    }
    .owl-background {
      background-image: url('assets/backgrounds/winter-owl-blue.png');

      @media (max-width: 991px) {
        background-image: url('assets/backgrounds/winter-owl-blue-mobile.png');
      }
    }
  }

  .home-container-item {
    width: 980px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 1030px) {
      width: 100%;
    }
    @media (min-width: 1625px) {
      width: 1120px;
    }
    @media (max-width: 991px) {
      &.home-title-container {
        position: relative;
      }
    }

    > .home-container-background {
      position: absolute;
      z-index: -1;
      width: calc(100% + 40px);
      height: calc(100% + 60px);
      margin-left: -20px;
      margin-right: -20px;
      margin-top: -30px;
      background-repeat: no-repeat;
      background-size: auto;
    }
  }

  .owl-background {
    position: absolute;
    right: 0;
    top: -204px;
    height: 205px;
    width: 408px;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: -1;

    @media (max-width: 991px) {
      top: auto;
      bottom: -30px;
      height: 100px;
      width: 100px;
      background-position: right;
      right: -21px;
    }
  }
}

.home-title.h3 {
  @media (max-width: 991px) {
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
}

.home-subtitle {
  @extend .text-main, .text-muted;

  @media (max-width: 991px) {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

.home-title-themed {
  @include theme.get-color("color", "color-white");

  .text-muted, .text-normal {
    @include theme.get-color("color", "color-white", '', !important);
  }

  > * {
    width: 71%;

    @media (max-width: 1624px) {
      width: 66%;
    }

    @media (max-width: 991px) {
      width: 90%;
    }

    @media (max-width: 580px) {
      width: 80%;
    }
  }
}

.tabbed-container-wrapper {
  width: 100%;
  margin: 88px 0 40px 0;
  margin-left: auto;
  margin-right: auto;
  height: 600px;
  margin-bottom: -194px;
  position: relative;

  &.with-shadow {
    width: 1440px;

    .tabbed-container-border {
      box-shadow: none;
    }

    .tabbed-container-wrapper-background {
      position: absolute;
      top: 9px;
      left: -3px;
      width: 100%;
      height: 100%;
      background-image: url('assets/backgrounds/home-page-shadow.png');
      background-position: center;
      background-repeat: no-repeat;

      @media (min-width: 1625px) {
        background-image: url('assets/backgrounds/home-page-shadow-lg.png');
      }
    }

    @media (min-width: 1625px) {
      width: 1575px;
    }
    @media (max-width: 1480px) {
      width: 100vw;
      margin-left: -20px;
    }

    @media (max-width: 1030px) {
      .home-container-item {
        width: calc(100% - 40px);
      }
    }
  }

  .tabbed-container {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    max-height: 480px;
    height: 370px;

    @media screen and (max-width: 991px) {
      margin: 0;
    }
  }
}

.tabbed-container-border {
  @include theme.get-color("background", "color-background");
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  align-items: flex-start;
}

.tabbed-container-tabs {
  margin-top: -48px;
  display: flex;
  flex-direction: row;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: relative;
  gap: 4px;

  &:after {
    background-color: #fff;
    height: 10px;
    content: "";
    position: absolute;
    bottom: -10px;
    left: 0;
    right: -10px;
  }
}

.tabbed-container-tab {
  @include theme.get-color("background", "color-home-tab");

  & > a {
    height: 48px;
    min-width: 202px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    padding: 0 20px;
    @include theme.get-color('color', 'color-text-muted', '', !important);

    &:hover {
      text-decoration: none;
      @include theme.get-color('color', 'color-text', '', !important);

      & > i {
        filter: none;
      }
    }
  }

  &:hover {
    @include theme.get-color("background", "color-hover-background");
  }

  &:not(.tabbed-container-tab-selected) {
    > a {
      position: relative;

      &:after {
        content: '';
        width: 100%;
        height: 16px;
        opacity: 0.1;
        background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.50) 28.12%, rgba(0, 0, 0, 0.00) 99.98%);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }
}

.tabbed-container-tab-selected {
  border-right: none !important;
  @include theme.get-color('background', 'color-background');

  & > a {
    @include theme.get-color('color', 'color-text', '', !important);
  }

  &:hover {
    @include theme.get-color('color', 'color-text');
    @include theme.get-color('background', 'color-background');
  }
}

.tabbed-container-tabs .tabbed-container-tab:last-child {
  border-bottom: none !important;
}

.tabbed-container-content {
  padding: 40px;
  width: 100%;
  height: 100%;
}

.tabbed-container-pane {
  height: 100%;
  width: 100%;
  padding: 0 !important;
}

.home-description {
  text-align: justify;
}

.file-picker-container {
  height: 166px;
  text-align: center;
  cursor: pointer;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  @include theme.get-color("border", "color-picker-border", 1px dashed);

  &.disabled {
    cursor: default;
    pointer-events: none;
    @include theme.get-color('background-color', 'color-background-disabled');
    @include theme.get-color('border', 'color-border-disabled', 1px dashed);

    &:focus,
    &:active,
    &:hover,
    &.dragover {
      @include theme.get-color('background-color', 'color-background-disabled');
      @include theme.get-color('border', 'color-border-disabled', 1px dashed);
    }
  }

  &:focus,
  &:active,
  &:hover,
  &.dragover {
    @include theme.get-color('background-color', 'color-soft-button-hover-background');
    @include theme.get-color('border', 'color-border-hover', 1px dashed);

    & p {
      @include theme.get-color('color', 'color-text');
    }
  }

  &:hover {
    & .dropzone-folder-icon {
      @extend .owl-hunting;
    }
  }
  &:focus,
  &:active,
  &.dragover {
    & .dropzone-folder-icon {
      @extend .owl-catching;
    }
  }
}

.home-container:not(.simple) {
  .file-picker-container, #upload-link {
    @include theme.get-color("border", "color-picker-border", 1px dashed);
    @include theme.get-color("background", "color-modal-filter-background");

    &:focus, &:active, &:hover, &.dragover  {
      @include theme.get-color("background-color", "color-hover-background-lighter");
    }
  }
}

.dropzone-folder-icon {
  @extend .owl-waiting;
  width: 132px;
  height: 80px;
}

.dropzone-text {
  @extend .text-main;
  @include theme.get-color('color', 'color-text-muted');
}

.scanner-label {
  @extend .h4;
  margin: 0;
  padding-bottom: 4px;

  @media screen and (max-width: 991px) {
    padding-bottom: 10px;
  }

  .scanner-label-highligh {
    @include theme.get-color("color", "color-primary");
  }
}

.button-upload {
  width: 100%;
}

.upload-link-feedback.active {
  margin-bottom: -20px;
}
