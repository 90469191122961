@use '../theme.scss';
@use '../vars.scss';
@use '../icons.scss';

.form-switch {
  display: flex;
  align-items: center;

  .form-check-input {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 20px;
    width: 40px;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.15s ease;

    &:before {
      @extend .ds3-icon;
      @include theme.get-color('color', 'color-white');
      font-size: 12px;
      margin-left: 3px;
      margin-top: -1px;
    }

    &:after {
      content: url('../assets/icons/opswat/circle.svg');
      line-height: 20px;
      padding-top: 1px;
    }

    &:checked {
      @extend .ds3-check;
      background-color: #{vars.$blue};
      border-color: #{vars.$blue};

      &:after {
        margin-right: 1px;
      }
    }

    &:not(:checked) {
      @extend .ds3-close;
      background-color: #{vars.$page500};
      border-color: #{vars.$page500};
      flex-direction: row-reverse;

      &:before {
        font-size: 14px;
        margin-right: 2px;
      }

      &:after {
        margin-left: 1px;
      }
    }

    &:focus {
      box-shadow: none;
    }
  }

  .form-check-label {
    margin-right: 0;
  }
}
