@use '../vars';
@use '../theme';
@use '../fonts';
@use '../icons';

.alert {
  border: none;
  display: flex;
  align-items: flex-start;
  line-height: 20px;
  padding: 12px 36px 12px 12px;
  @include theme.get-color('color', 'color-text');

  &.alert-fixed-top {
    position: fixed;
    top: 12px;
    z-index: 1056; // To be visible above modal and it's backdrop
    width: 100%;
    border-top: none;

    @media (min-width: 800px) {
      top: 8px;
      right: 8px;
      width: 479px;
    }

    @media (min-width: 992px) {
      top: 18px;
      right: 16px;
    }
  }

  &:before {
    @include fonts.icon-font;
    line-height: 20px;
    margin-right: #{vars.$gap3};
  }

  &.alert-danger {
    background-color: #{vars.$red-600};

    &:before {
      @extend .ds3-failed;
      @include theme.get-color('color', 'color-malicious');
    }
  }
  &.alert-warning {
    background-color: #{vars.$yellow-600};

    &:before {
      @extend .ds3-warning;
      @include theme.get-color('color', 'color-suspicious');
    }
  }
  &.alert-success {
    background-color: #{vars.$green-600};

    &:before {
      @extend .ds3-success;
      @include theme.get-color('color', 'color-no_threat');
    }
  }
  &.alert-info {
    background-color: #{vars.$blue-600};

    &:before {
      @extend .ds3-information-block;
      color: #{vars.$blue};
    }
  }

  .important {
    @extend .small-header;
    margin-bottom: 0;
    margin-right: #{vars.$gap1};
    display: inline-block;
  }

  .btn-close {
    padding: 15px;
    display: flex;
    align-items: center;
  }
}
