@use '../../../../../scss/fonts';
@use '../../../../../scss/vars';
@use '../../../../../scss/theme';
@use '../../../../../scss/overrides';

.html-report {
  max-width: 1400px;
  width: 100%;
  margin: auto;
  background-color: white;
  padding: 16px 0;

  .accordion {
    .collapse {
      max-height: none;
    }

    .card {
      &:hover {
        @include vars.standard-shadow;
      }

      .card-header {
        cursor: default;

        .accordion-button {
          height: 100%;
          cursor: default !important;

          .tags-preview,
          .card-actions,
          .mitre-preview {
            display: none !important;
          }
        }
      }
    }
  }

  .ds3-icon {
    margin: 0 !important;
    padding: 0 !important;

    &:before {
      content: '' !important;
    }
  }

  .ioc-table {
    td {
      vertical-align: top !important;
    }
  }

  .report-content {
    .report-block {
      margin-bottom: 36px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .filehash-export-header {
    @extend .border-bottom, .small-header;
    font-size: 13px;
    display: block;
    padding-bottom: 8px;
    margin-top: 36px;
    margin-bottom: 32px;
  }

  .thumbs-list .report-thumbnail {
    width: 100%;
    max-width: unset;
    max-height: unset;
    cursor: unset;
  }

  .nav.nav-tabs,
  .strings-files-links {
    display: none;
  }

  .tab-content > .tab-pane {
    display: block !important;
    opacity: 1 !important;
    padding: 0 0 24px 0;
  }

  .export-only {
    display: block !important;
  }

  .ui-only {
    display: none;
  }

  .derived-files {
    margin-top: 36px;
  }

  .accordion {
    .card {
      box-shadow: none !important;
      @include theme.get-color('border', 'color-table-border', 1px solid);

      .card-header {
        height: 40px !important;
      }

      .card-body > div:not(.body-items-container) {
        padding: 20px 16px !important;
      }
    }
  }

  .card {
    box-shadow: none !important;
    @include theme.get-color('border', 'color-table-border', 1px solid);
    border-bottom: none;
  }

  .collapse-with-icon .collapse-icon {
    width: 42px !important;
    min-width: 42px !important;
    height: 42px !important;
  }

  .table tr td,
  .table tr th {
    padding: 6px 8px;
  }
}

.export-only {
  display: none !important;
}
