@use '../vars';
@use '../theme';

.tooltip {
  background-color: #{vars.$page1100};
  opacity: 1;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;

  .tooltip-inner {
    padding: 4px 8px;
  }
}
