@use '../theme';
@use '../fonts';
@use '../icons';
@use '../text-color';

@mixin files-accordion-cells-sizing {
  &:first-child {
    width: 35%;
    font-weight: 500;
  }
  &:nth-child(2) {
    width: 40%;
  }
  &:nth-child(3) {
    width: 15%;
  }
  &:nth-child(4) {
    width: 10%;
  }

  @media (max-width: 815px) {
    &:nth-child(2) {
      width: 50%;
    }
    &:nth-child(3) {
      display: none;
    }
    &:nth-child(4) {
      width: 15%;
    }
  }

  @media (max-width: 500px) {
    &:first-child {
      width: 40%;
    }
    &:nth-child(2) {
      width: 60%;
    }
    &:nth-child(4) {
      display: none;
    }
  }
}

.table-accordion {
  > .general-accordion-header {
    display: flex;
  }

  > .accordion {
    > .accordion-item {
      border: none;
      @include theme.get-color('border-top', 'color-border-lightest', 1px solid);

      &:last-child {
        @include theme.get-color('border-bottom', 'color-border-lightest', 1px solid);
      }

      > .accordion-header {
        > .accordion-button {
          display: flex;
          padding: 10px 8px;
          transition: background-color 50ms;

          &:hover {
            @include theme.get-color('background', 'color-hover-table-background');
          }

          &:after {
            display: none;
          }
          &:before {
            @include fonts.icon-font;
            position: absolute;
            left: 8px;
          }
          &.collapsed {
            @extend .ds3-solid-right;
          }
          &:not(.collapsed) {
            @extend .ds3-solid-down;
          }

          > div {
            padding-right: 10px;

            &:first-child {
              padding-left: 24px;
            }
          }
        }
      }

      > .accordion-collapse {
        max-height: none;

        > .accordion-body {
          padding: 32px 8px 32px 32px;
        }
      }
    }
  }

  &.files-accordion {
    margin-top: 12px;

    > .general-accordion-header {
      padding: 10px 8px;

      > div {
        width: 10%;
        font-weight: 500;
        padding-right: 10px;

        @include files-accordion-cells-sizing;
      }
    }

    > .accordion {
      > .accordion-item {
        > .accordion-header {
          > .accordion-button {
            > div {
              width: 10%;

              @include files-accordion-cells-sizing;
            }
          }
        }
      }
    }
  }
}

.accordions-container {
  width: 100%;
  display: block;
  margin-top: 16px;
  margin-bottom: 8px;

  &.fullWidth {
    @media screen and (max-width: 991px) {
      width: 100%;
    }

    & * .accordion-item {
      border: 0 !important;
    }
  }

  // ---- Override React Bootstrap Accordion ----

  .accordion-item {
    @include theme.get-color('border', 'color-border-divider', 1px solid);
    border-bottom: 0;

    &:first-child {
      @include theme.get-color('box-shadow', 'color-shadow', 0px -2px 8px);
    }

    &:last-child {
      // @include theme.get-color("border-bottom", "color-border-divider", 1px solid);
      @include theme.get-color('box-shadow', 'color-shadow', 0px 2px 8px);
    }
  }

  .accordion-button {
    @include theme.get-color('border', 'color-border-divider', 1px solid);

    @media screen and (max-width: 991px) {
      padding: 20px;
    }

    &.collapsed {
      @include theme.get-color('background-color', 'color-background-disabled');
    }

    &.collapsed::after {
      background-image: url('assets/icons/opswat/chevron_down.svg');
    }

    &:not(.collapsed) {
      &::after {
        background-image: url('assets/icons/opswat/chevron_up.svg') !important;
      }

      > a {
        @extend .text-muted;
      }
    }

    > a {
      @extend .color-text-main;
      text-decoration: none;
    }
  }

  .accordion-body {
    @media screen and (max-width: 991px) {
      padding: 20px;
    }
  }
}

.accordion-button::after {
  margin-right: 12px;
}
