@use '../theme.scss';

.dropdown-container {
  position: relative;
  width: 100%;

  .value-container {
    height: 100%;
    cursor: pointer;

    .value {
      display: flex;
      align-items: center;
    }
  }

  & > div > .dropdown-menu.show {
    max-height: 207px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.dropdown-input > input {
  cursor: pointer;
  caret-color: transparent;
}

.custom-dropdown-menu {
  width: 100%;
  margin-top: 4px;
}

.dropdown-group-title {
  cursor: default;
}

.dropdown-item {
  cursor: pointer;
}
