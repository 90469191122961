@use '../vars';
@use '../theme';

.overview-with-tabs {
  .overview-by-sections {
    .data-block {
      margin-bottom: 28px;
      margin-top: 20px;
    }
  }

  .overview-tabs {
    @include theme.get-color('border', 'color-border-lightest', 1px solid);
    padding: 24px 16px;

    .collapse-toggle-panel {
      &.moved-for-all-tabs {
        position: absolute;
        top: -103px;
        right: -3px;
      }
    }
  }
}
