@use '../../../../../scss/theme';
@use '../../../../../scss/vars';

// General modal layout
.modal-backdrop.show {
  opacity: 0.8;
  background-color: #{vars.$page1100};
}

.modal {
  --bs-modal-footer-gap: 0;

  .modal-content {
    width: auto;
    border: none;
    border-radius: 0;

    .modal-body {
      padding: 20px;

      > .modal-footer {
        padding: 36px 0 0;
      }
    }

    .modal-header {
      padding: 20px;
      border: none;

      .modal-title {
        margin-bottom: 0;

        .icon-button {
          vertical-align: middle;
        }
      }
    }

    > .modal-footer {
      padding: 16px 20px;
    }

    .modal-footer {
      border-top: none;

      .modal-footer-content {
        gap: #{vars.$gap6};
      }
    }
  }

  .modal-dialog {
    &:not(.modal-size-auto) {
      .modal-content {
        min-width: 100%;
      }
    }
  }
}

// Various special cases of layout
.modal-with-tabs {
  min-width: 900px;

  @media (max-width: 1000px) {
    min-width: calc(100vw - 100px);
  }

  .modal-header {
    border-bottom: none;
  }

  .modal-body {
    padding: 0 15px 15px;

    .card-embedded-tabs {
      .nav-tabs {
        margin-left: -15px;
        margin-right: -15px;
        @include theme.get-color('border-bottom', 'color-border', 1px solid);
      }

      .tab-content {
        margin: 0 0 15px;
        padding: 0;
        max-height: calc(100vh - 200px);
        overflow-y: auto;
        overflow-x: clip;
      }
    }
  }

  // In case we need to move overflow area a bit do show, say, active button border
  &.modal-less-tab-left-margin {
    .modal-body {
      .card-embedded-tabs {
        .tab-content {
          margin: 0 0 15px -3px;
          padding-left: 3px;
        }
      }
    }
  }
}

.modal-size-auto {
  width: auto;
  max-width: calc(100vw - 100px);
  max-height: 100vh;
  justify-content: center;

  @media (max-width: 575px) {
    & {
      max-width: calc(100vw - 10px);
    }
  }

  &.modal-big-height {
    align-items: flex-start;

    .modal-content {
      img {
        max-height: none;
      }
    }
  }

  .modal-content {
    flex-basis: content;

    img {
      max-width: calc(100vw - 50px);
      max-height: calc(100vh - 100px);
    }

    @media (min-width: 576px) {
      .col-sm {
        flex-basis: content;
      }
    }
  }

  .pre-view {
    max-height: calc(100vh - 215px);
    overflow-y: auto;
  }
}

.modal {
  .modal-dialog:not(.modal-with-standard-data-row) {
    .data-row {
      padding: 7px;

      &:nth-child(2n + 1) {
        background: rgb(240, 240, 240) !important;
      }

      .data-row {
        padding: 0;
        background: transparent;
      }
    }
  }
}

.modal-content-full-width {
  .modal-content {
    width: 100%;
  }
}

.modal-body-screen-width {
  display: flex;
  align-items: stretch;
  height: calc(100vh - 60px);

  .modal-content {
    height: 100%;

    .modal-body {
      height: 100%;
      width: calc(100vw - 48px);
      overflow: hidden;
    }
  }
}

.modal-body-max-screen-height {
  display: flex;
  align-items: center;
  max-height: calc(100vh - 60px);

  .modal-content {
    height: 100%;
    max-height: calc(100vh - 60px);

    .modal-body {
      height: 100%;
      max-width: calc(100vw - 48px);
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}

.modal.avatar-editor-modal {
  .modal-dialog {
    margin-top: -50px;
  }

  @media (max-width: 548px) {
    .modal-content {
      width: 100%;
    }
  }

  .avatar-editor-container {
    @media (min-width: 549px) {
      padding: 50px 140px;
    }
  }
}

.min-width-modal {
  .modal-content {
    width: 535px;
    flex-basis: auto;

    @media (max-width: 575px) {
      width: 100vw;
    }
  }
}

.spinner-border.in-modal-footer {
  width: 16px;
  height: 16px;
  margin-right: 11px;
  border-width: 3px;
}

.modal-body {
  .decode-view-container {
    @media (max-width: 990px) {
      .decoded {
        width: auto;
      }

      .hexViewerBody {
        width: calc(100vw - 50px) !important;
      }
    }
  }
}

.modal-fix-width-1 {
  max-width: 735px;

  .modal-body {
    @media (min-width: 750px) {
      width: 735px;
    }
  }
}

.modal-fix-width-2 {
  .modal-dialog {
    max-width: 552px;
  }
}

.modal-footer-inside-body {
  .modal-body {
    padding-bottom: 0;
  }

  .modal-footer {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.modal-max-width-medium {
  max-width: 1376px !important;
}

.modal-max-width-large {
  max-width: 1500px !important;
}

.modal-header {
  .icon-button {
    &.icon-copy {
      font-size: 16px;
    }
  }
}

.modal-only {
  display: none;
}
.modal {
  .modal-only {
    display: inherit;
  }
}

.modal-footer-border {
  .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .modal-footer {
      @include theme.get-color('border', 'color-border-lightest', 1px solid);
      margin: 0 -20px -16px -20px !important;
      padding: 16px !important;
    }
  }
}

.modal-header-border {
  .modal-header {
    @include theme.get-color('border-bottom', 'color-border-lightest', 1px solid, !important);
  }
}
