@use '../theme';
@use '../vars';

.popover {
  --bs-popover-border-color: #{vars.$page200};

  box-shadow: 0px 2px 8px 0px rgba(10, 36, 86, 0.15);
  position: absolute;
  border: none;
  border-radius: 0;
  padding: 4px 20px 0px 20px;
  min-width: 300px;

  &.fade {
    transition: opacity 0.1s linear;
  }

  .popover-header,
  .popover-body {
    @extend %general-color;
  }

  .popover-header {
    padding: 16px 0 16px;
  }

  .popover-body {
    padding: 16px 0 16px 0;
  }
}

.popover-container {
  position: relative;
}

.popover-wide {
  width: 500px;
  max-width: 550px;
}

.popover-body.alert-danger {
  color: #721c24;
}

.custom-popover-body {
  display: flex;
  flex-direction: row;

  @media (max-width: 499px) {
    flex-direction: column;
  }
}

.click-persistent-popup {
  left: 27px;
  top: -25px;

  &.no-title {
    padding-top: 0;

    .popover-body {
      padding-top: 6px;
    }

    .popover-arrow {
      position: relative;
      top: 27px;
      left: -27px;
    }
  }

  &.popup-copyable {
    .popover-body {
      position: relative;

      .icon-copy {
        position: absolute;
        top: -9px;
        right: -13px;
      }
    }
  }
}
