@use 'vars';
@use 'theme';

html,
body {
  @extend %general-color;
  font-family:
    'Roboto',
    sans-serif,
    Apple Color Emoji,
    Segoe UI Emoji,
    Segoe UI Symbol,
    Noto Color Emoji;
  font-size: 14px;
  height: 100vh;
  line-height: 20px;
  margin: 0;
  padding: 0;

  #root {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  @media (max-width: 991px) {
    width: 100vw;
    overflow-x: auto;
  }
}

@media print {
  html,
  body {
    height: auto;

    #root {
      height: auto;
    }
  }
}

.main-container {
  flex-grow: 1;
  padding-top: #{vars.$main-container-p-t};
  padding-bottom: #{vars.$main-container-p-b};
  padding-left: #{vars.$main-container-p-x};
  padding-right: #{vars.$main-container-p-x};

  @media screen and (max-width: 991px) {
    padding-top: #{vars.$main-container-p-t-mobile};
    padding-bottom: #{vars.$main-container-p-b-mobile};
    padding-left: #{vars.$main-container-p-x-mobile};
    padding-right: #{vars.$main-container-p-x-mobile};
  }
}

input,
.general-colors {
  @extend %general-color;
}

input.form-check-input {
  background-repeat: no-repeat;
  margin-top: 0.25rem;
  border: 2px gray solid;
}

input,
select,
textarea {
  @include theme.get-color('border-color', 'color-border');
}

select {
  @include theme.get-color('background', 'color-background');
  @include theme.get-color('color', 'color-text');

  & option:hover,
  & option:active {
    @include theme.get-color('background', 'color-hover-background');
  }
}

.very-small {
  font-size: 0.6875rem;
}

.container-xl {
  max-width: 1280px;
}

.container-lg {
  max-width: 1012px;
}

.hover-bg-light:hover {
  background-color: var(--light);
}

.cursor-pointer {
  cursor: pointer !important;
}

.react-bootstrap-table {
  td {
    white-space: pre-line;
  }
}

.page-loader {
  height: calc(100vh - #{vars.$top-menu-total-h} - #{vars.$footer-h} - #{vars.$main-container-p-t});
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    width: 100px;
    margin-top: -100px;
  }

  .spinner-container {
    margin-top: -110px;
    margin-bottom: 10px;
    opacity: 0.9;
  }
}

.small-page-loader {
  .page-loader {
    height: 250px;

    .spinner-container {
      margin-top: 0;
    }
  }

  &.chart-loader {
    .page-loader {
      height: 670px;
    }
  }
}

.alert-container {
  display: flex;

  .alert-center {
    margin-left: auto;
    margin-right: auto;
  }
}

.form-check {
  .form-check-input {
    margin-bottom: 0.25rem;
    border: 1px gray solid;
  }

  label {
    margin-left: 5px;
  }
}

.form-check-help {
  margin-left: 23px;
}

.modal-dialog {
  max-width: 510px;
}

.text-muted-light {
  color: rgb(210, 210, 210);
}

table.narrow-table.hover tr.table-subheader:hover {
  background: inherit;
  cursor: default;
}

#enterprise-features {
  left: 25px;
  z-index: 1;
  height: 50px;
  display: inline-flex;
  align-items: center;
}

.feature {
  display: inline-block;
  color: var(--vt-grey-600);
  margin-right: 4px;
  font-size: 14px;
  text-decoration: none;

  &:hover {
    color: #084ea3;
    text-decoration: none;
  }
}

.table-subheader:nth-of-type(n + 2) td {
  padding-top: 15px;
}

.custom-modal-szie {
  max-width: none !important;
}

.link-alert {
  font-size: 15px;
  padding: 1px 5px;
  display: inline;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.btn-outline-dark-soft {
  @include theme.get-color('color', 'color-text');
  &:not(:disabled):not(.disabled) {
    @include theme.get-color('background-color', 'color-button-background');
  }

  &:not(:disabled):not(.disabled):hover {
    @include theme.get-color('background-color', 'color-soft-button-hover-background');
  }

  &:not(:disabled):not(.disabled):active {
    background-color: rgb(230, 230, 230);
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(152, 158, 164, 0.5);
  }
}

.centered-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  height: calc(
    100vh - #{vars.$top-menu-total-h} - #{vars.$footer-h} - #{vars.$main-container-p-t} - #{vars.$main-container-p-b}
  );
  justify-content: center;

  .small-content-upper {
    margin-top: -300px;
  }
}

.inline-label {
  line-height: 32px;
}

.appbar-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
}

.user-menu {
  @extend %general-color;
  @include theme.get-color('border', 'color-border', 1px solid);

  .avatar-placeholder {
    font-size: 42px;
    margin-bottom: 10px;
    display: inline-block;
    cursor: default;
  }

  .appbar-avatar {
    width: 42px;
    height: 42px;
    cursor: default;
  }
}

.absolute-center {
  position: absolute;
  left: calc(50% - 16px);
  top: calc(50% - 16px);
}

select:required:invalid {
  color: #9fa3a7;
}

/* Reset the non-placeholder options, or they'll be styled when the dropdown opens! */
select:required:invalid > option:not(:first-child),
optgroup {
  color: initial;
  font-style: normal;
}

.red-icon {
  color: #dc3545;
}

.green-icon {
  color: green;
}

.fs-100 {
  font-size: 100%;
}

.dangerous-text {
  color: #dc3545;
}

a.dangerous-text:hover {
  color: rgb(190, 23, 49);
}

.user-item-control {
  padding: 7px 15px;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }

  i {
    font-size: 18px;
  }
}

.card {
  .card-title {
    text-transform: capitalize;
  }
}

.top-note {
  font-size: 15px;
}

.btn .icon-button.icon-copy {
  margin: 0;
  width: 100%;
  height: 100%;
  display: block;
  padding: 10px 15px;
}

.standard-light-border {
  border-color: #ced4da !important;
}

.card.alert {
  padding: 0.5rem 1.25rem;
}

.anchor-disabled {
  pointer-events: none;
}

.opacity-inactive {
  opacity: 0.3;
  pointer-events: none;
}

.card-title {
  .alert {
    font-size: 15px;
    padding: 1px 12px;
    text-transform: none;
    vertical-align: middle;
  }
}

.alert.alert-small {
  padding: 2px 5px;
  margin: 0;
  margin-right: 5px;
}

.overall-activity-content {
  height: 500px;

  & .activity-labels {
    height: 100px;
  }

  & .pie-chart {
    height: 360px;

    & .chart {
      height: 100%;
      width: 100%;
    }
  }

  @media (max-width: 480px) {
    & .activity-labels {
      height: 100px;
    }

    & .pie-chart {
      height: 400px;
    }
  }
}

.words-break {
  word-wrap: anywhere;
  word-break: break-all;
}

.table-bordered-padding {
  td {
    padding: 8px;
  }
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width);
}

.orange-round-icon {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ffa600;
  margin-left: -12px;
  margin-right: 5px;
}

.normal-light-gray {
  font-weight: normal;
  color: #888;
}

.text-url {
  color: #084ea3;
  font-style: italic;
}

.hidden-link {
  color: inherit !important;
  text-decoration: none !important;
}

input[disabled].light {
  background: rgba(233, 236, 239, 0.6);
}

.collapse-remove-icon {
  opacity: 0.2;
  cursor: pointer;
  padding: 5px;

  &:hover {
    opacity: 0.4;
  }
}

.pre-view {
  background: rgb(243, 243, 243);
  border: 1px solid #dee2e6;
}

.text-light-muted {
  color: rgb(204, 204, 204) !important;
}

.near-link-spinner {
  width: 17px;
  height: 17px;
  border-width: 3px;
}

a:not(.btn).disabled {
  @include theme.get-color('color', 'color-primary-text-disabled', '', !important);
  pointer-events: none;
  cursor: default;
}

.with-badge {
  position: relative;
  border-bottom: 2px solid #{vars.$orange} !important;
  padding-bottom: 2px !important;

  &.red-badge {
    border-bottom: 2px solid #{vars.$red} !important;
  }
}

.table-row-borders {
  tr {
    border-top: 1px solid #dee2e6;

    td {
      padding: 8px 5px;
      padding-left: 0;
    }

    th {
      padding-left: 0;
    }
  }

  tr:last-of-type {
    border-bottom: 1px solid #dee2e6;
  }
}

.table-align-row-top {
  td {
    vertical-align: baseline !important;
  }
}

.wrapped-text {
  word-wrap: anywhere;
}

.border-cover {
  background: white;
  height: 3px;
}

.text-green {
  color: green;
}

.text-red {
  color: #dc3545;
}
a.text-red {
  &:hover {
    color: #c83040;
  }
}

.btn-text {
  @extend %general-color;
  @extend %general-active;

  @include theme.get-color('border-color', 'color-border');
}

.simple-code-editor {
  border: 1px solid #dee2e6;
  border-radius: 4px;
}

.home-logo-container {
  display: flex;
  justify-content: center;
  height: 180px;
  align-items: center;

  img {
    max-width: 380px;
  }
}

.progress.muted-progress {
  .progress-bar {
    background: #848c93;
    opacity: 0.8;
  }
}

.list-bullet-symbol-tick {
  list-style: none;
  list-style-type: none;
  padding: 0;

  li::before {
    content: url(/assets/icons/opswat-check-sign.png);
    color: #148b14;
    size: 8px 11px;
    padding-right: 12px;
  }
}

.signup-page-title {
  font-weight: bold;
  font-size: 17px;
  line-height: 32px;
  padding-bottom: 8px;
}

.signup-list-title {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 8px;
}

.signup-list-item {
  font-weight: 400px;
  font-size: 14px;
  line-height: 20px;
  padding-top: 8px;
}

.card-header-transparent {
  @include theme.get-color('background-color', 'color-background');
}

.chart-row-item {
  @media (min-width: 1550px) {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .chart-content {
    max-height: 320px;
    overflow-y: auto;
  }
}

.chart-no-data-note {
  margin-top: 15px;
}

table.hidden-headers {
  th {
    display: none;
  }
}

.maitenance-note {
  text-align: center;
  border: 1px dashed burlywood;
}

.pre-wrap {
  white-space: pre-wrap;
}

.button-spinner {
  width: 14px;
  height: 14px;
  border-width: 3px;
}

.form-group {
  .invalid-feedback {
    font-size: inherit;
  }
}

.near-select-loader {
  width: 16px;
  height: 16px;
  border-width: 3px;
  position: absolute;
  right: -24px;
}

.extra-button {
  @media (max-width: 1250px) {
    display: none;
  }
}

.nav-item {
  .nav-link {
    text-transform: capitalize;
  }
}

.row.row-no-padding > * {
  padding-left: 0;
  padding-right: 0;
}

.form-label-container.inline-label {
  @media (min-width: 576px) {
    display: flex;
    justify-content: end;
  }
}

.text-default {
  text-transform: none !important;
}

.hide-desktop {
  @media (min-width: 992px) {
    display: none !important;
  }
}

.hide-mobile {
  @media (max-width: 991px) {
    display: none !important;
  }
}

.hide-mobile {
  @media (max-width: 991px) {
    display: none !important;
  }
}

.kids-border-top {
  > *:not(:first-child) {
    @include theme.get-color('border-top', 'color-border-lightest', 1px solid);
  }
}

.border-top {
  @include theme.get-color('border-top', 'color-border-lightest', 1px solid);
}

.border-left {
  @include theme.get-color('border-left', 'color-border-lightest', 1px solid);
}

.highlighted-code {
  pre {
    @extend .font-size-12, .line-height-150;

    padding: 0 !important;
    background: transparent !important;
    tab-size: 4;
  }
}

a.lonely-link {
  text-decoration: underline !important;
}

.text-break-all {
  word-break: break-all;
}

.long-string {
  .cut-description {
    color: #{vars.$page700};
    font-size: 12px;
  }
}

.collapse-toggle-panel {
  &.for-all-tabs {
    position: absolute;
    right: 0;
    top: 3px;
  }
}

.cursor-default {
  cursor: default !important;
}

.hide-desktop,
.hide-desktop-cancellable {
  @media screen and (min-width: 992px) {
    display: none !important;
  }
}

.hide-mobile {
  @media screen and (max-width: 991px) {
    display: none !important;
  }

  .hide-desktop-cancellable {
    @media screen and (min-width: 992px) {
      display: flex !important;
    }
  }
}

.label-icon-container {
  > div {
    display: flex;
  }
}

.text-transform-none {
  text-transform: none;
}

.overflow-y-auto {
  overflow-y: auto;
}

input[disabled] {
  cursor: default !important;
}
