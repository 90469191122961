@use 'theme';
@use 'vars';

// Redefine Bootstrap 5 variables
:root {
  --bs-info-rgb: 23, 162, 184;
  --bs-border-radius: 0px;
}

.card {
  --bs-card-title-spacer-y: 0.75rem;
  --bs-card-spacer-y: 1.25rem;
  --bs-card-spacer-x: 1.25rem;
  --bs-card-cap-padding-y: 0;
  --bs-card-cap-padding-x: 0;
}

.accordion {
  --bs-accordion-active-bg: none;
  --bs-accordion-btn-bg: transparent;
  --bs-accordion-btn-padding-y: 0.75rem;
  --bs-accordion-btn-padding-x: 1rem;
}

.list-group {
  --bs-list-group-item-padding-y: 0.8rem;
}

.navbar-nav .nav-link {
  --bs-navbar-nav-link-padding-x: 0;
}
