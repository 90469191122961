@use 'theme';
@use 'fonts';
@use 'vars';

.mitre-search-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 100%;
}

.mitre-filter {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  @include theme.get-color('border-bottom', 'color-border-lightest', 1px solid);

  @media screen and (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;

    & > div {
      width: 100%;
    }
  }
}

.mitre-mobile-body {
  display: none;

  @media screen and (max-width: 991px) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
  }
}

.mitre-search-body {
  @media screen and (max-width: 991px) {
    display: none;
  }

  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0px 20px;
  overflow: hidden;
}

.mitre-list-header {
  @extend .text-m;
  min-height: 40px;
  display: flex;
  align-items: center;
  @include theme.get-color('border-bottom', 'color-border-lightest', 1px solid);
  overflow-y: hidden;
  scrollbar-gutter: stable; //required to align fixed header columns with scrollable list
}

.mitre-header-column {
  flex-grow: 1;
  max-width: 33.33%;
  padding: 10px 20px;

  @media screen and (max-width: 991px) {
    max-width: 100%;
  }
}

.mitre-list-wrapper {
  display: flex;
  max-height: 100%;
  flex-grow: 1;
  overflow-y: auto;
  scrollbar-gutter: stable;

  @media screen and (max-width: 991px) {
    max-height: calc(100vh - 365px);
    flex-direction: column;
  }
}

.mitre-items-list {
  flex-grow: 1;
  max-width: 33.33%;

  @media screen and (max-width: 991px) {
    max-width: 100%;

    & > div:last-child {
      @include theme.get-color('border-bottom', 'color-border-lightest', 1px solid, !important);
    }
  }
}

.mitre-tactic-accordion {
  cursor: pointer;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  @include theme.get-color('border', 'color-border-lightest', 1px solid);
  border-top: none !important;
  @include theme.get-color('background-color', 'color-soft-button-hover-background');

  &.selected {
    @include theme.get-color('background-color', 'color-background');
    border-right: none !important;
  }
}

.mitre-technique-accordion {
  @extend .mitre-tactic-accordion;

  @include theme.get-color('background-color', 'color-background');
  border-left-width: 0px !important;

  &.overflows {
    border-left-width: 1px !important;
  }

  @media screen and (max-width: 991px) {
    &.selected {
      border: none !important;
    }
  }
}

.mitre-subtechnique-line {
  @extend .mitre-technique-accordion;
  justify-content: flex-start;

  @include theme.get-color('background-color', 'color-background');
  cursor: default;

  @media screen and (max-width: 991px) {
    border: none !important;
    padding: 10px 20px 10px 44px;
  }
}

.number-box {
  @include theme.get-color('background-color', 'color-hover-background');
  padding: 0 4px !important;
  margin: 0 10px;
}
