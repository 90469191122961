@use '../../../../../scss/theme';
@use '../../../../../scss/vars';

.extracted-url,
.extracted-domain,
.extracted-uuid,
.extracted-ip,
.extracted-registry-path,
.extracted-hash,
.extracted-email,
.extracted-revision-save-ids {
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  @include theme.get-color('color', 'color-text');
}

.ioc-geo-link {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.full-width {
  width: 100%;
}

.long-text {
  display: flex;
  align-items: center;
  padding: 0 15px 0 2px;
  max-width: 100%;
}

.responsive-popover {
  max-width: 50%;
}

@media (max-width: 800px) {
  .responsive-popover {
    max-width: 60%;
  }
}

@media (max-width: 640px) {
  .responsive-popover {
    max-width: 80%;
  }
}

.iocs-query-form {
  @include vars.standard-shadow;
}

table.ioc-table {
  tr:not(:last-child) {
    .origin-row {
      padding-bottom: #{vars.$gap11};
    }
  }

  tbody {
    tr:last-child {
      border-bottom: none;

      td {
        border-bottom: none;
      }
    }
  }
}
