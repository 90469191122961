@use 'theme';
@use 'vars';

%list-container {
  max-width: 720px;
  background: rgba(210, 220, 240, 0.2);
  padding: 10px 15px;
  @include theme.get-color('border', 'color-border', 1px solid);
  border-radius: 0;
  display: flex;
  flex-direction: column;
}

.list-container {
  @extend %list-container;
}

.data-block {
  display: table;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  > .data-row {
    display: table-row;

    > .label,
    > .value {
      display: table-cell !important;
      padding-bottom: 4px;
    }

    > .value {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .data-row {
    word-wrap: break-word;
    @include theme.get-color('background-color', 'color-background', '', !important);

    &.value-rows {
      display: flex;
      flex-wrap: wrap;

      .label {
        width: 100%;
      }

      .value {
        @extend %list-container;
        margin-top: 7px;
        margin-left: 25px;
      }
    }

    &.object-rows {
      > .value {
        > .data-row {
          margin-bottom: 5px;
        }
      }
    }

    .label {
      display: inline;
      vertical-align: top;
      margin-right: 10px;
      font-weight: 500;
      font-size: 13px;
    }

    .value {
      display: inline-block;
      vertical-align: top;
      font-weight: 400;
      font-size: 13px;
      color: #{vars.$page900};

      .icon-copy,
      .icon-show-long-string {
        opacity: 0;
      }

      .show-hidden-icons {
        .icon-copy,
        .icon-show-long-string {
          opacity: 1 !important;
        }
      }
    }

    &:hover {
      .icon-copy,
      .icon-show-long-string {
        opacity: 1;
      }
    }

    .data-row {
      .label {
        color: #6c757d;
      }
    }
  }
}

.data-block {
  &.no-labels-text-transform {
    .data-row {
      .label {
        text-transform: none;
      }
    }
  }
}

.data-block {
  &.plain-labels {
    .data-row {
      .label {
        font-weight: normal;
      }
    }
  }
}

.row-as-column {
  .data-row {
    display: flex;
    flex-direction: column;

    .label {
      line-height: 150%;
      margin-bottom: 10px;
    }

    .value {
      padding-left: 0;
    }

    .data-row {
      display: inline-block;

      .label,
      .value {
        margin-bottom: 0;
      }
    }
  }

  &.compact {
    .data-row {
      .label {
        line-height: 150%;
        margin-bottom: 0;
      }

      .value {
        padding-left: 0;
        margin-bottom: 8px;
      }

      .data-row {
        display: inline-block;

        .label,
        .value {
          margin-bottom: 0;
        }
      }
    }
  }
}

.data-blocks-no-gap {
  > .data-block {
    margin-bottom: 0;
  }
}

.labels-middle {
  .data-row .label {
    vertical-align: middle;
  }
}

.align-data-block-labels {
  .data-block > .data-row > .label {
    min-width: 160px;
  }
}

.url-details-overview {
  .data-block > .data-row > .label {
    width: 135px;
  }
}
