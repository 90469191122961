@use 'theme.scss';

.indicator-search-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 100%;
}

.threat-indicators-filter {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  @include theme.get-color('border-bottom', 'color-border-lightest', 1px solid);

  .dropdown-item {
    text-transform: none !important;
  }

  @media screen and (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;

    & > div {
      width: 100%;
    }
  }
}

.indicators-search-body {
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 0px 0px 20px;
  overflow: hidden;
}

.indicators-list-header {
  @extend .text-m;
  padding: 10px 0 10px 8px;
  margin-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include theme.get-color('border-bottom', 'color-border-lightest', 1px solid);
}

.indicators-list-wrapper {
  max-height: 100%;
  flex-grow: 1;
  overflow-y: auto;
}

.indicators-type-accordion-title {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 10px 8px;
  margin-right: 20px;
  @include theme.get-color('border-bottom', 'color-border-lightest', 1px solid);
}

.threat-indicator-group-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 10px 32px;
  margin-right: 20px;
  @include theme.get-color('border-bottom', 'color-border-lightest', 1px solid);

  @media screen and (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
}

.threat-indicator-group-labels {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 991px) {
    width: 100%;
    gap: 8px;
  }
}
