@use '../../../../../scss/vars';
@use '../../../../../scss/fonts';
@use '../../../../../scss/theme';

$menu-height: 60px;

@mixin active-item {
  padding-top: 4px;
  border-bottom: 4px solid #{vars.$blue};
  background: #{vars.$nav300};
}

@mixin menu-shadow {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20px;
  opacity: 0.05;
  background: linear-gradient(180deg, black 0%, rgba(0, 0, 0, 0.5) 28%, rgba(0, 0, 0, 0) 100%);
}

.report-menu {
  display: flex;
  margin-left: -20px;
  margin-top: -22px;
  width: calc(100% + 40px);
  height: $menu-height;
  border-top: 1px solid #{vars.$nav300};
  background: #{vars.$nav100};

  @media (max-width: 1279px) {
    margin-left: -20px;
    width: calc(100% + 40px);
  }

  @media (max-width: 991px) {
    margin-top: -32px;
    position: relative;
    z-index: 99;
  }

  > .row-items {
    height: 100%;
    flex-grow: 1;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    flex-direction: row;
    overflow: hidden;

    @media (max-width: 991px) {
      display: none;
    }

    > a {
      height: 100%;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      padding: 0 20px;
      align-items: center;
      flex-wrap: nowrap;
      height: 100%;
      color: #{vars.$page1100} !important;
      position: relative;

      &.active {
        @include active-item;
      }

      &:hover {
        @include theme.get-color('background-color', 'color-nav-hover-background');
      }

      @media (max-width: 991px) {
        width: 100%;
        justify-content: flex-start;
      }

      &:after {
        @include menu-shadow;
      }
    }
  }

  .responsive-dropdown-wrapper {
    height: 100%;

    .responsive-dropdown-toggle {
      padding: 0 20px;
      text-wrap: nowrap;
      display: flex;
      height: 100%;
      align-items: center;
      cursor: pointer;
      position: relative;

      &.active {
        @include active-item;
      }

      &:hover {
        @include theme.get-color('background-color', 'color-nav-hover-background');
      }

      &:after {
        @include menu-shadow;
      }
    }

    .dropdown-menu {
      display: flex;
      flex-direction: column;
      background: #{vars.$nav100};
      padding: 8px 0;
      box-shadow: none;
      border-top: none;

      > * {
        padding: 6px 40px 6px 16px;
        color: #{vars.$page1100} !important;

        &.active {
          position: relative;

          &:after {
            @include fonts.icon-font;

            content: '\e921';
            position: absolute;
            top: 8px;
            right: 13px;
            color: #{vars.$blue} !important;
          }
        }

        &:hover {
          @include theme.get-color('background-color', 'color-nav-hover-background');
        }
      }
    }
  }

  .mobile-menu {
    @media (min-width: 992px) {
      display: none;
    }

    .navbar {
      padding: 0;

      .navbar-toggler {
        @extend .text-main;

        height: $menu-height;
        padding: 15px 20px;
        border-bottom: 1px solid #{vars.$nav300};
      }

      .navbar-collapse {
        max-height: calc(100vh - 186px);
        overflow-y: scroll;

        .navbar-nav {
          > a {
            padding: 15px 20px;
            color: #{vars.$page1100} !important;
            background: #{vars.$nav100};
            border-bottom: 1px solid #{vars.$nav300};

            &.active {
              padding-left: 16px;
              border-left: 4px solid #{vars.$blue};
              background: #{vars.$nav300};
              position: relative;

              &:after {
                @include fonts.icon-font;

                content: '\e921';
                position: absolute;
                right: 20px;
                color: #{vars.$blue} !important;
              }
            }
          }
        }
      }
    }
  }
}
