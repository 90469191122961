@use '../../../../../scss/vars';
@use '../../../../../scss/theme';

.progress-block {
  @include theme.get-color('border-right', 'color-border-lightest', 1px solid);

  .progress-bar-container {
    @extend .text-label-bold;
    padding: 16px;
  }
}

.progress-block,
.upload-file-item {
  .subtasks {
    @extend .text-label;
    padding: 16px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .all-finished {
      text-align: center;
      color: #{vars.$page700};
    }
  }

  .alert {
    margin-bottom: -16px;
    margin-left: -16px;
    margin-right: -16px;
    text-transform: none;
    @include theme.get-color('border-top', 'color-border-lightest', 1px solid);
  }
}

.spinner-border.text-secondary {
  width: 15px !important;
  height: 15px !important;
  border-width: 2px !important;
  border-color: #{vars.$page800};
  border-right-color: transparent;
}

.progress-bar-container {
  &.scan-progress-bar {
    @include theme.get-color('border-bottom', 'color-border-lightest', 1px solid);
  }

  .progress {
    height: 4px;
    background-color: #{vars.$page200};

    .progress-bar {
      background-color: #{vars.$page400};
    }
  }

  &.verdict-malicious {
    .progress {
      background-color: #{vars.$red-600};

      .progress-bar {
        @include theme.get-color('background-color', 'color-malicious');
      }
    }
  }

  &.verdict-likely_malicious {
    .progress {
      background-color: #{vars.$orange-600};

      .progress-bar {
        @include theme.get-color('background-color', 'color-likely_malicious');
      }
    }
  }

  &.verdict-suspicious {
    .progress {
      background-color: #{vars.$yellow-600};

      .progress-bar {
        @include theme.get-color('background-color', 'color-suspicious');
      }
    }
  }

  &.verdict-informational,
  &.verdict-no_threat {
    .progress {
      background-color: #{vars.$green-600};

      .progress-bar {
        @include theme.get-color('background-color', 'color-no_threat');
      }
    }
  }

  &.verdict-benign {
    .progress {
      background-color: #{vars.$blue-600};

      .progress-bar {
        @include theme.get-color('background-color', 'color-benign');
      }
    }
  }

  &.variant-primary {
    .progress {
      background-color: #{vars.$page200};

      .progress-bar {
        @include theme.get-color('background-color', 'color-primary');
      }
    }
  }

  &.variant-secondary {
    .progress {
      background-color: #{vars.$page200};

      .progress-bar {
        background-color: #{vars.$page800};
      }
    }
  }

  &.variant-dark {
    .progress {
      background-color: #{vars.$page200};

      .progress-bar {
        background-color: #{vars.$page1100};
      }
    }
  }
}

.upload-file-item {
  .progress-block {
    .progress-bar-container {
      @extend .text-label-bold;
      padding: 0 0 16px;
      border-bottom: none;
    }
  }
}

.generic-progress-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  .progress {
    width: 124px;
  }

  > .label {
    margin-left: 16px;
  }
}

.report-filter {
  .generic-progress-bar {
    width: 124px;

    .progress {
      width: 80px;
      border-radius: 10px;
      padding: 2px;
      height: 8px;

      .progress-bar {
        border-radius: 10px;
      }
    }

    > .label {
      @extend .text-label-bold;
      width: 40px;
      height: 20px;
      text-align: center;
      border-radius: 10px;
      background-color: #{vars.$page200};
      margin-left: 4px;
    }
  }
}
